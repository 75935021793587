import styled from 'styled-components';
import { FaMotorcycle, FaEdit, FaTimesCircle } from 'react-icons/fa';
import { IoCloseCircleOutline, IoBriefcaseSharp } from 'react-icons/io5';
import Button from 'src/components/Button';

export const StyledModal = styled.div`
  z-index: 100;
  background: ${({ theme }) => theme.colors.cardBackground};
  position: relative;
  margin: auto;
  border-radius: 15px;
`;

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ModalContainer = styled.div`
  width: 80%;
  min-width: 800px;
  outline: 0;
  z-index: 700;
`;

export const Header = styled.div`
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px 20px;
  background-color: ${({ theme }) => theme.colors.mainBackground};
`;

export const HeaderText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.text};
`;

export const IconMotoboy = styled(FaMotorcycle)`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.ultraLarge};
  margin-right: 8px;
`;

export const CloseButton = styled(Button)`
  background-color: transparent;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 40px;
  padding: 0;
`;

export const CloseIcon = styled(IoCloseCircleOutline)`
  font-size: ${({ theme }) => theme.fontSizes.ultraLarge};
  margin: 0 10px 0;
  color: ${({ theme }) => theme.colors.textError};
`;

export const Content = styled.div`
  padding: 10px 20px 10px;
  overflow-x: hidden;
  overflow-y: hidden;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  max-height: 80vh;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const RowHistoric = styled(Line)`
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 10px;
  padding: 15px;
  margin: 15px 0;
  justify-content: space-around;
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(23, 25, 28, 0.9);
  z-index: 500;
`;

export const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color};
  text-align: center;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  position: absolute;
  bottom: -5px;
  right: -8px;
`;

export const DeliveryNumber = styled.div`
  background-color: ${({ theme }) => theme.colors.mainBackground};
  padding: 10px;
  border-radius: 7px;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ color }) => color};
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  position: relative;
`;

export const IsEditedIcon = styled(FaEdit)`
  color: ${({ theme }) => theme.colors.white};
  width: 14px;
  height: 14px;
`;

export const IsDeletedIcon = styled(FaTimesCircle)`
  color: ${({ theme }) => theme.colors.white};
  width: 14px;
  height: 14px;
`;

export const ClientContainer = styled.div`
  margin-left: 13px;
  /* white-space: nowrap; */
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 3px;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.text};
`;

export const CustomerIcon = styled(IoBriefcaseSharp)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin-right: 10px;
  color: ${({ color }) => color};
`;

export const MotoboyIcon = styled(FaMotorcycle)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.disabled};
`;

export const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  color: ${({ color }) => color};
`;

export const DescriptionContainer = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  background-color: ${({ theme }) => theme.colors.mainBackground};
  color: ${({ color }) => color};
  padding: 8px;
  border-radius: 8px;
  margin: 10px 0;
  max-width: 150px;
  word-break: break-all;
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;

  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ color }) => color};
`;

export const CostLabel = styled(Label)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ color }) => color};
  padding-right: 10px;
`;
