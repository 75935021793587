import styled from 'styled-components';
import Input from 'src/components/Input';
import CustomerSelectMulti from 'src/components/CustomerSelectMulti';
import Button from 'src/components/Button';

export const Grid = styled.div`
  display: grid;
  padding: 10px;
`;

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 75px);
`;

export const FilterContainer = styled.div`
  width: calc(100% - 20px);
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 10px;
  margin: 10px 10px 0px 10px;
  background-color: ${({ theme }) => theme.colors.cardBackground};
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.text};
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
export const ButtonCard = styled(Flex)`
  margin: 30px 18px 0 0;
`;

export const Customer = styled(CustomerSelectMulti).attrs({
  margin: '30px 18px 0 0',
  width: '280px',
  height: '30px',
  fontSize: '14px',
  label: 'buscar clientes',
})``;

export const MotoboyInput = styled(Input).attrs({
  placeholder: 'buscar motoqueiros',
  id: 'motoboy',
  type: 'text',
  autocomplete: 'on',
  margin: '30px 18px 0 0',
  width: '220px',
})`
  font-size: 16px;
`;

export const ExportButton = styled(Button)`
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.background};
  font-size: 12px;
  width: 100px;
  margin: 30px 18px 0 0;
`;

export const ExportCard = styled(Flex)`
  /* margin: 30px 18px 0 0; */
  /* background-color: ${({ theme }) => theme.colors.background}; */
  padding: 5px;
  border-radius: 10px;
  justify-content: space-around;
  align-items: center;
`;
