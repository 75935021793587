import {
  CellContainer,
  EditButtonIcon,
  EmptyMessage,
  EmpytContainer,
  PayButtonIcon,
  ApproveButtonIcon,
  UserContainer,
  CustomerIcon,
  Label,
} from './styles';
import DataTable, { createTheme } from 'react-data-table-component';

import { IInvoices } from 'src/types';
import Loader from 'src/components/Loader';
import React from 'react';
import { useTheme } from 'styled-components';

interface Props {
  invoices?: IInvoices[];
  isLoading: boolean;
  generateInvoice: (customerId: number) => void;
  filtersHeight: number;
}

createTheme('jucaTheme', {
  text: {
    primary: '#FFFFFF',
    secondary: 'rgba(255, 255, 255, 0.7)',
    disabled: 'rgba(0,0,0,.12)',
  },
  background: {
    default: '#424242',
  },
  context: {
    background: '#E91E63',
    text: '#FFFFFF',
  },
  divider: {
    default: 'transparent',
  },
  button: {
    default: '#FFFFFF',
    focus: 'rgba(255, 255, 255, .54)',
    hover: 'rgba(255, 255, 255, .12)',
    disabled: 'rgba(255, 255, 255, .18)',
  },
  selected: {
    default: 'rgba(0, 0, 0, .7)',
    text: '#FFFFFF',
  },
  highlightOnHover: {
    default: 'rgba(0, 0, 0, .7)',
    text: '#FFFFFF',
  },
  striped: {
    default: 'rgba(0, 0, 0, .87)',
    text: '#FFFFFF',
  },
});

const InvoicesTable: React.FC<Props> = ({
  invoices,
  isLoading,
  generateInvoice,
  filtersHeight,
}) => {
  const { colors } = useTheme();

  const columns = React.useMemo(
    () => [
      {
        name: 'Nome',
        cell: ({ client }: IInvoices) => (
          <UserContainer>
            <CustomerIcon color={colors.primary} />
            <Label color={colors.white}>{client.name}</Label>
          </UserContainer>
        ),
        style: {
          minWidth: '300px',
        },
      },
      {
        name: 'N⁰ de Corridas Faturadas',
        cell: ({ totalDeliveriesBilled }: IInvoices) => (
          <CellContainer>N⁰ de corridas faturadas: {totalDeliveriesBilled}</CellContainer>
        ),
        style: {
          maxWidth: '300px',
        },
      },
      {
        name: 'N⁰ Total Corridas',
        cell: ({ totalDeliveries }: IInvoices) => (
          <CellContainer>total corridas: {totalDeliveries}</CellContainer>
        ),
        style: {
          minWidth: '120px',
        },
      },
      {
        name: 'Valor total',
        cell: ({ total }: IInvoices) => (
          <CellContainer>
            {total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
          </CellContainer>
        ),
        style: {
          maxWidth: '150px',
          minWidth: '150px',
        },
      },
      {
        name: 'Pago',
        cell: ({ pay, client }: IInvoices) => (
          <CellContainer>
            {pay === 'late' && (
              <PayButtonIcon onClick={() => generateInvoice(client.customerId)}>
                Pagar Fatura
              </PayButtonIcon>
            )}
            {pay === 'open' && <EditButtonIcon>Em aberto</EditButtonIcon>}
            {pay === 'pay' && <ApproveButtonIcon>Fatura Paga</ApproveButtonIcon>}
          </CellContainer>
        ),
      },
    ],
    [colors.primary, colors.white, generateInvoice],
  );

  const customStyle = React.useMemo(
    () => ({
      table: {
        style: {
          padding: '0 10px 0',
          backgroundColor: colors.mainBackground,
          color: colors.text,
        },
      },
      rows: {
        style: {
          fontSize: '13px',
          color: colors.text,
          backgroundColor: colors.cardBackground,
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
          minHeight: '60px',
          margin: '8px 0 8px',
          borderRadius: '10px',
        },
      },
      pagination: {
        style: {
          backgroundColor: colors.mainBackground,
          color: colors.white,
        },
      },
    }),
    [colors],
  );

  return (
    <DataTable
      subHeader={false}
      noHeader
      noTableHead
      responsive
      pagination
      style={{
        display: 'flex',
        alignItems: invoices && invoices.length > 0 ? 'flex-start' : 'center',
        justifyContent: 'center',
        height: `calc(100% - ${filtersHeight + 60}px)`,
        overflow: 'auto',
      }}
      paginationComponentOptions={{
        rowsPerPageText: 'Quantidade de linhas:',
        rangeSeparatorText: 'Total de Registros:',
        noRowsPerPage: true,
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
      }}
      columns={columns}
      data={invoices == null ? [] : invoices}
      theme="jucaTheme"
      customStyles={customStyle}
      noDataComponent={
        <EmpytContainer>
          {isLoading ? (
            <Loader color={colors.primary} />
          ) : (
            <EmptyMessage>Lista vazia</EmptyMessage>
          )}
        </EmpytContainer>
      }
    />
  );
};

export default InvoicesTable;
