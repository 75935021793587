import Button from 'src/components/Button';
import { FaExclamationCircle } from 'react-icons/fa';
import { IoCloseCircleOutline } from 'react-icons/io5';
import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  outline: 0;
`;

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(23, 25, 28, 0.9);
  z-index: 500;
`;

export const StyledModal = styled.div`
  z-index: 100;
  background: ${({ theme }) => theme.colors.mainBackground};
  position: relative;
  margin: auto;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
`;

export const Header = styled.div`
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px;
  background-color: ${({ theme }) => theme.colors.mainBackground};
`;

export const HeaderText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.text};
`;

export const AlertIcon = styled(FaExclamationCircle)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin: 0 10px 0;
  color: ${({ theme }) => theme.colors.text};
`;

export const CloseButton = styled(Button)`
  background-color: transparent;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 40px;
  padding: 0;
`;

export const CloseIcon = styled(IoCloseCircleOutline)`
  font-size: ${({ theme }) => theme.fontSizes.ultraLarge};
  margin: 0 10px 0;
  color: ${({ theme }) => theme.colors.primary};
`;

export const Content = styled.div`
  padding: 10px 20px 10px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const Message = styled.div`
  font-size: 1.5rem;
  padding: 20px 20px 40px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
`;
export const YesButton = styled(Button)`
  width: 100px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  margin: 0 0 0 10px;
`;
export const NoButton = styled(Button)`
  width: 100px;
  text-align: center;
  background: transparent;
  color: ${({ theme }) => theme.colors.primary};
  border: 2px solid ${({ theme }) => theme.colors.primary};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 49px;
`;
