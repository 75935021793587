import { FaBriefcase, FaPlus, FaRegEnvelope } from 'react-icons/fa';
import { IoCloseCircleOutline, IoPhonePortraitOutline } from 'react-icons/io5';
import styled, { css } from 'styled-components';

import Button from 'src/components/Button';
import Input from 'src/components/Input';

interface InputError {
  error: boolean;
}

export const ModalContainer = styled.div`
  max-width: 1200px;
  min-width: 800px;
  width: 70%;
  outline: 0;
  z-index: 700;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  max-height: 80vh;
  @media (max-height: 800px) {
    max-height: 60vh;
    overflow: auto;
  }
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const SaveButton = styled(Button)`
  margin: ${({ theme }) => theme.spacings.large};
  width: 200px;
  background-color: ${({ theme }) => theme.colors.secondary};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
`;

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
  width: 100%;
  height: 100%;
`;

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(23, 25, 28, 0.9);
  z-index: 500;
`;

export const StyledModal = styled.div`
  z-index: 100;
  background: ${({ theme }) => theme.colors.cardBackground};
  position: relative;
  margin: auto;
  border-radius: 15px;
`;

export const Header = styled.div`
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacings.large};
  background-color: ${({ theme }) => theme.colors.mainBackground};
`;

export const HeaderText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.ultraLarge};
  color: ${({ theme }) => theme.colors.text};
`;

export const Content = styled.div`
  padding: ${({ theme }) => theme.spacings.large};
  overflow-x: hidden;
  overflow-y: auto;
`;

export const AddIcon = styled(FaPlus)`
  font-size: ${({ theme }) => theme.fontSizes.ultraLarge};
  margin: 0 10px 0;
  color: ${({ theme }) => theme.colors.text};
`;

export const CloseButton = styled(Button)`
  background-color: transparent;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  padding: 0;
`;

export const CloseIcon = styled(IoCloseCircleOutline)`
  font-size: ${({ theme }) => theme.fontSizes.ultraLarge};
  margin: 0 10px 0;
  color: ${({ theme }) => theme.colors.textError};
`;

export const UserIcon = styled(FaBriefcase)`
  font-size: 90px;
  margin: 0 40px 0 20px;
  color: ${({ theme }) => theme.colors.primary};
`;

export const EmailIcon = styled(FaRegEnvelope)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin: 0 10px 0;
  color: ${({ theme }) => theme.colors.textLight};
`;

export const PhoneIcon = styled(IoPhonePortraitOutline)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin: 0 10px 0;
  color: ${({ theme }) => theme.colors.textLight};
`;

export const NameInput = styled(Input).attrs({
  placeholder: 'Digite o nome do cliente',
  id: 'customerName',
  type: 'text',
  margin: '0',
  width: '100%',
})<InputError>`
  font-size: ${({ theme }) => theme.fontSizes.cardTitle};
  text-align: left;
  padding-left: 20px;
  ${({ error }) =>
    error &&
    css`
      border: 2px solid red;
    `}
`;

export const EmailInput = styled(Input).attrs({
  placeholder: 'e-mail',
  id: 'customerEmail',
  type: 'email',
  width: '100%',
  margin: '10px 10px 0 0',
})<InputError>`
  text-align: left;
  padding-left: 50px;
  ${({ error }) =>
    error &&
    css`
      border: 2px solid red;
    `}
`;

export const PhoneInput = styled(Input).attrs({
  placeholder: 'telefone',
  id: 'customerPhone',
  type: 'tel',
  margin: '10px 0 0 10px ',
  width: '100%',
})<InputError>`
  text-align: left;
  padding-left: 50px;
  ${({ error }) =>
    error &&
    css`
      border: 2px solid red;
    `}
`;

export const PrimaryInfoContainer = styled(Line)`
  padding-top: ${({ theme }) => theme.spacings.large};
  padding-bottom: ${({ theme }) => theme.spacings.large};
`;

export const PersonalInfoContainer = styled.div`
  width: 100%;
  margin: ${({ theme }) => theme.spacings.large};
  padding: ${({ theme }) => theme.spacings.large};

  border: 1px solid ${({ theme }) => theme.colors.mainBackground};
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
  border-radius: 10px;
`;

export const PersonalInfoMessage = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.text};
`;

export const AddressInput = styled(Input).attrs({
  placeholder: 'Endereço',
  id: 'customerAddress',
  type: 'text',
  width: '100%',
  margin: '10px 0 0',
})<InputError>`
  font-size: ${({ theme }) => theme.fontSizes.cardTitle};
  text-align: left;
  padding-left: 20px;
  ${({ error }) =>
    error &&
    css`
      border: 2px solid red;
    `}
`;

export const ContactInput = styled(Input).attrs({
  placeholder: 'Contato principal',
  id: 'customerContact',
  type: 'text',
  width: '100%',
  margin: '10px 0 0',
})<InputError>`
  font-size: ${({ theme }) => theme.fontSizes.cardTitle};
  text-align: left;
  padding-left: 20px;
  ${({ error }) =>
    error &&
    css`
      border: 2px solid red;
    `}
`;

export const CnpjInput = styled(Input).attrs({
  placeholder: 'CNPJ',
  id: 'customerCnpj',
  type: 'text',
  width: '100%',
  margin: '10px 10px 0 0',
})<InputError>`
  font-size: ${({ theme }) => theme.fontSizes.cardTitle};
  text-align: left;
  padding-left: 20px;
  ${({ error }) =>
    error &&
    css`
      border: 2px solid red;
    `}
`;

export const AccessContainer = styled(Line)`
  margin-top: ${({ theme }) => theme.spacings.large};
`;

export const Label = styled.div`
  font-style: normal;
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.text};
`;

export const ColumnField = styled(Column)`
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 10px;
  width: 100%;
`;

export const LabelError = styled(Label)`
  height: 22px;
  padding-top: 4px;
  padding-left: 10px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;
