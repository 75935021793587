export default (text: string | undefined): string => {
  if (text) {
    return text
      .replace(/[^\d]/g, '')
      .substring(0, 11)
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  }
  return '';
};
