import React from 'react';
import { Container, Footer, Graph, GraphContainer, Title } from './styles';

interface GraphItem {
  pos: number;
  month: string;
  value: number;
  total: number;
}

interface Props {
  title: string;
  footer: string;
  data: GraphItem[];
}

const BarGraph: React.FC<Props> = ({ data, title, footer }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <GraphContainer>
        <Graph
          groupMode="grouped"
          data={data}
          labelFormat={(labelValue: string) => {
            if (labelValue.toString().includes('.')) {
              return (
                <tspan y={-10} style={{ fontSize: 10 }}>
                  {labelValue.toString().replace('.', ',')}
                </tspan>
              ) as unknown as string;
            }

            return (
              <tspan
                y={-10}
                style={{
                  fontSize: 10,
                }}
              >
                {labelValue}
              </tspan>
            ) as unknown as string;
          }}
        />
      </GraphContainer>
      <Footer>{footer}</Footer>
    </Container>
  );
};

export default BarGraph;
