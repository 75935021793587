import {
  AuthForm,
  BackButton,
  Container,
  Description,
  EmailInput,
  LoaderContainer,
  LoginContainer,
  SendEmailButton,
  Status,
  TitleContainer,
} from './styles';

import { FiMail } from 'react-icons/fi';
import Loader from 'src/components/Loader';
import { ReactComponent as Logo } from 'src/assets/svg/logo.svg';
import { PutForgotPassword } from 'src/services/user';
import React from 'react';
import { useTheme } from 'styled-components';

const ForgotPassword = (): JSX.Element => {
  const { isLoading, refetch: putForgotPassword } = PutForgotPassword();
  const [email, setEmail] = React.useState<string>('');
  const [error, setError] = React.useState<string>('');
  const theme = useTheme();

  const onChangeEmail = (value: string) => {
    setError('');
    setEmail(value);
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await putForgotPassword({ email });
      if (response) {
        setError(response.data.msg ?? 'Verifique o seu e-mail para mais instruções.');
        setEmail('');
        return;
      }
    } catch (e: any) {
      setError(e.response?.data.msg ?? 'Ocorreu um erro ao precossar as informações.');
    }
    setError('Ocorreu um erro ao precossar as informações.');
  };

  return (
    <Container>
      <TitleContainer>
        <Logo />
      </TitleContainer>
      <AuthForm onSubmit={onSubmit} noValidate>
        <LoginContainer>
          <Description>
            Insira um endereço de email <br />
            associado com a sua conta.
          </Description>
        </LoginContainer>
        <EmailInput text={email} icon={<FiMail />} onChangeValue={onChangeEmail} />
        <SendEmailButton disabled={isLoading} type="submit">
          ENVIAR
        </SendEmailButton>
        <BackButton to="/login" type="button">
          VOLTAR PARA O LOGIN
        </BackButton>
        <LoaderContainer>
          {isLoading ? <Loader color={theme.colors.primary} /> : <Status>{error}</Status>}
        </LoaderContainer>
      </AuthForm>
    </Container>
  );
};

export default ForgotPassword;
