import { IPut, SessionContextType, THeaders } from 'src/types';
import { useMutation, useQueryClient } from 'react-query';

import api from 'src/services/api';

interface ICommonPut {
  key: string;
  headers?: THeaders;
  onFailure?: SessionContextType['refreshSession'];
}

type TPut<T, F> = ICommonPut &
  (
    | {
        url: string;
        customMutationFn?: never;
        onHeadersChanged?: never;
      }
    | {
        url?: never;
        customMutationFn(data: F): Promise<T>;
        onHeadersChanged(headers: THeaders): void;
      }
  );

export function putMutationFn<T, F>(
  url: string,
  data: F,
  headers?: THeaders,
): Promise<T> {
  return api.put(url, data, { headers });
}

export default function usePut<T, F = void>({
  key,
  url,
  headers: headersParam,
  onFailure,
  customMutationFn,
  onHeadersChanged,
}: TPut<T, F>): IPut<T, F> {
  const queryClient = useQueryClient();
  let headers = headersParam;
  const {
    isLoading,
    isError,
    error,
    isSuccess,
    mutateAsync: refetch,
  } = useMutation<T, Error, F>(
    key,
    customMutationFn ?? ((data) => putMutationFn(url, data, headers)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
      retry: (failureCount, { message }) => {
        if (message?.includes('401')) {
          if (failureCount === 0) {
            onFailure?.()?.then((token) => {
              if (token) {
                headers = {
                  ...headersParam,
                  'x-access-token': token,
                };
                onHeadersChanged?.(headers);

                queryClient.invalidateQueries(key);
              }
            });
          }

          return failureCount < 3;
        }

        return false;
      },
    },
  );

  return { isLoading, isError, error, isSuccess, refetch };
}
