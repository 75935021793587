import axios from 'axios';

const api = axios.create({ baseURL: process.env.REACT_APP_API_URL });

api.interceptors.request.use((request) => {
  // console.log({request});
  return request;
});

api.interceptors.response.use(
  (response) => {
    // console.log({response});
    return response;
  },
  (error) => {
    // console.log({error});
    return Promise.reject(error);
  },
);

export default api;
