import { Container, Item, Line, Logout, UserName } from './styles';
import { FaSignInAlt, FaUserCircle } from 'react-icons/fa';

import ConfirmationModal from 'src/components/ConfirmationModal';
import { LogoIcon } from 'src/components/Icon';
import React from 'react';
import { useSession } from 'src/hooks/useSession';
import { useTheme } from 'styled-components';

const TopBar: React.FC = () => {
  const { colors } = useTheme();
  const {
    logout,
    session: { user },
  } = useSession();
  const [modalVisibility, setModalVisibility] = React.useState<boolean>(false);

  const onLogout = (event: React.MouseEvent): void => {
    event.preventDefault();
    setModalVisibility(true);
  };
  const onConfirm = () => logout();
  const onCancel = () => setModalVisibility(false);

  return (
    <Container>
      <Item>
        <LogoIcon width={180} />
      </Item>
      <Item>
        <FaUserCircle color={colors.white} size={30} />
        <UserName>{user?.name?.toUpperCase()}</UserName>
        <Line />
        <Logout
          type="submit"
          onClick={onLogout}
          iconRight={<FaSignInAlt color={colors.textError} />}
        >
          SAIR
        </Logout>
      </Item>

      <ConfirmationModal
        header="Atenção"
        show={modalVisibility}
        onConfirm={onConfirm}
        onCancel={onCancel}
        message="Tem certeza que deseja sair"
      />
    </Container>
  );
};

export default TopBar;
