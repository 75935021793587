import {
  IDeliveriesResponse,
  IGetDeliveries,
  IPutResponse,
  IReceipts,
  IResponse,
  IPut,
  SessionContextType,
  TDefaultResponse,
  IDelete,
  IExportResponse,
  IGetReportStatusResponse,
} from 'src/types';
import useFetch from 'src/hooks/useFetch';
import usePut from 'src/hooks/usePut';
import useDelete from 'src/hooks/useDelete';

export const FetchDeliveries = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
  filters: IGetDeliveries,
): IResponse<IDeliveriesResponse> => {
  let url = `/owner/getDeliveries/${filters.limit}/${filters.page}/?startdate=${filters.startdate}&finaldate=${filters.finaldate}&paymentTypes=${filters.paymentTypes}`;
  if (filters.client) url = url.concat(`&client=${filters.client}`);
  if (filters.motoboy) url = url.concat(`&motoboy=${filters.motoboy}`);
  if (filters.pay !== undefined) url = url.concat(`&pay=${filters.pay}`);

  const { isLoading, refetch, error, data } = useFetch<IDeliveriesResponse>({
    key: 'deliveries',
    url,
    headers: { 'x-access-token': token },
    enabled: true,
    onFailure: refreshSession,
  });

  return { isLoading, refetch, error, data };
};

export const ExportDeliveries = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
  filters: IGetDeliveries,
): IResponse<IExportResponse> => {
  let url = `/receipt/generateReport/${filters.limit}/${filters.page}/?startdate=${filters.startdate}&finaldate=${filters.finaldate}&paymentTypes=${filters.paymentTypes}`;
  if (filters.client) url = url.concat(`&client=${filters.client}`);
  if (filters.motoboy) url = url.concat(`&motoboy=${filters.motoboy}`);

  const { isLoading, refetch, error, data } = useFetch<IExportResponse>({
    key: 'exportDeliveries',
    url,
    headers: { 'x-access-token': token },
    enabled: false,
    onFailure: refreshSession,
  });

  return { isLoading, refetch, error, data };
};

export const PutDelivery = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
  receipt: IReceipts,
): IPut<TDefaultResponse<IPutResponse>, IReceipts> => {
  const { isLoading, isError, error, isSuccess, refetch } = usePut<
    TDefaultResponse<IPutResponse>,
    IReceipts
  >({
    key: 'putdelivery',
    url: `/receipt/owner/${receipt.deliveryId}`,
    headers: { 'x-access-token': token },
    onFailure: refreshSession,
  });

  return { isLoading, isError, error, isSuccess, refetch };
};

export const DeleteDelivery = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
  deliveryId: number,
): IDelete<TDefaultResponse> => {
  const { isLoading, isError, error, isSuccess, refetch } = useDelete<TDefaultResponse>({
    key: 'deletedelivery',
    url: `/receipt/owner/${deliveryId}`,
    headers: { 'x-access-token': token },
    onFailure: refreshSession,
  });

  return { isLoading, isError, error, isSuccess, refetch };
};

export const FetchReportStatus = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
  key: string,
): IResponse<IGetReportStatusResponse> => {
  const { data, isLoading, error, refetch } = useFetch<IGetReportStatusResponse>({
    key: 'getreportstatus',
    url: `/receipt/report-status/${key}`,
    headers: { 'x-access-token': token },
    onFailure: refreshSession,
    enabled: false,
  });

  return { data, isLoading, error, refetch };
};

export const PayReceipt = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
  receipt: IReceipts,
): IPut<TDefaultResponse<IPutResponse>, null> => {
  const { isLoading, isError, error, isSuccess, refetch } = usePut<
    TDefaultResponse<IPutResponse>,
    null
  >({
    key: 'putdelivery',
    url: `/receipt/payReceipt/${receipt.deliveryId}`,
    headers: { 'x-access-token': token },
    onFailure: refreshSession,
  });

  return { isLoading, isError, error, isSuccess, refetch };
};
