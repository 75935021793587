import styled, { css } from 'styled-components';

import Button from 'src/components/Button';
import Input from 'src/components/Input';

interface StrengthBarProps {
  isVisible: boolean;
}

export const Grid = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 2fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'profile profile' 'password email';
`;

const Card = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.cardBackground};
  flex: 1;
  margin: ${({ theme }) => theme.spacings.medium};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacings.large};
  min-width: 450px;
`;

export const ProfileCard = styled(Card)`
  grid-area: profile;
  margin-top: ${({ theme }) => theme.spacings.large};
  margin-left: ${({ theme }) => theme.spacings.large};
  margin-right: ${({ theme }) => theme.spacings.large};
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 50px 0;
`;

export const PasswordCard = styled(Card)`
  grid-area: password;
  margin-bottom: ${({ theme }) => theme.spacings.large};
  margin-left: ${({ theme }) => theme.spacings.large};
`;

export const EmailCard = styled(Card)`
  grid-area: email;
  margin-bottom: ${({ theme }) => theme.spacings.large};
  margin-right: ${({ theme }) => theme.spacings.large};
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  margin: 0 0 0 50px;
`;

export const UserName = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${({ theme }) => theme.fontSizes.profileTitle};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.text};

  overflow: hidden;
  white-space: nowrap;
`;

export const UserMail = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.primary};

  overflow: hidden;
  white-space: nowrap;
`;

export const CardTitle = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: ${({ theme }) => theme.fontSizes.cardTitle};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.text};

  white-space: nowrap;
`;

export const CardForm = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: ${({ theme }) => theme.spacings.large};
`;

export const CardBody = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
`;

export const CardDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  font-weight: ${({ theme }) => theme.fontWeights.light};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.text};
`;

export const PasswordInput = styled(Input).attrs({
  placeholder: 'senha atual',
  id: 'password',
  type: 'password',
  autoComplete: 'on',
  margin: '30px 0 0',
})``;

export const NewPasswordInput = styled(Input).attrs({
  placeholder: 'nova senha',
  id: 'newPassword',
  type: 'password',
  autoComplete: 'on',
  margin: '30px 0 0',
})``;

export const NewPsswordStrength = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 80%;

  max-width: 400px;
`;

export const PasswordStrengthBar = styled.div<StrengthBarProps>`
  width: 125px;
  height: 8px;

  background: ${({ theme, isVisible }) =>
    isVisible ? theme.colors.approve : 'transparent'};
  border-radius: 5px;
  margin: 10px 5px 0;

  ${({ theme, isVisible }) =>
    !isVisible &&
    css`
      border: 1px solid ${theme.colors.border};
    `}
`;

export const ConfirmPasswordInput = styled(Input).attrs({
  placeholder: 'confirmar nova senha',
  id: 'ConfirmPassword',
  type: 'password',
  autoComplete: 'on',
  margin: '10px 0 0',
})``;

export const SubmitButton = styled(Button)`
  width: 80%;
  max-width: 400px;
  margin: 40px 0 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 60px;

  margin: 30px 0 0px;
`;

export const ErrorDescription = styled.div`
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.textError};
`;

export const EmailInput = styled(Input).attrs({
  placeholder: 'insira seu novo e-mail',
  id: 'NewEmail',
  type: 'email',
  autoComplete: 'on',
  margin: '30px 0 0',
})``;
