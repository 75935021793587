import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  body {
    -webkit-font-smoothing: antialiased !important;
    background-color: ${(props) => props.theme.colors.mainBackground};
  }
  body html #root {
    height: 100%;
  }
  #root{
    min-width: 800px;
  }
`;

export default GlobalStyle;
