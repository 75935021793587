import { FaEdit, FaTrashAlt, FaMotorcycle, FaTimesCircle } from 'react-icons/fa';
import { IoBriefcaseSharp } from 'react-icons/io5';

import styled from 'styled-components';

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 3px;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.text};
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 115px;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ color }) => color};
`;

export const DeliveryNumber = styled.div`
  background-color: ${({ theme }) => theme.colors.mainBackground};
  padding: 10px;
  border-radius: 7px;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.extraBold};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ color }) => color};
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  position: relative;
`;

export const CostReceipt = styled.div`
  width: 100%;
`;

export const EditIcon = styled(FaEdit)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin: 6px;
  color: ${({ theme }) => theme.colors.white};
`;

export const TrashIcon = styled(FaTrashAlt)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin: 0 10px 0;
  color: ${({ theme }) => theme.colors.primary};
`;

export const ButtonIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 5px;
  height: 30px;
  width: 30px;
  margin-right: 8px;
  min-width: 30px;
`;

export const CustomerIcon = styled(IoBriefcaseSharp)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin-right: 10px;
  color: ${({ color }) => color};
`;

export const MotoboyIcon = styled(FaMotorcycle)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.disabled};
`;

export const IsEditedIcon = styled(FaEdit)`
  color: ${({ theme }) => theme.colors.white};
  width: 14px;
  height: 14px;
`;

export const IsDeletedIcon = styled(FaTimesCircle)`
  color: ${({ theme }) => theme.colors.white};
  width: 14px;
  height: 14px;
`;

export const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color};
  text-align: center;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  position: absolute;
  bottom: -5px;
  right: -8px;
`;

export const IsEditedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const EmpytContaienr = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.mainBackground};
  width: 100%;
  height: 100%;
`;

export const EmptyMessage = styled.div`
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.text};
`;

export const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  color: ${({ color }) => color};
`;

export const CostLabel = styled(Label)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ color }) => color};
  padding-right: 10px;
`;

export const DescriptionContainer = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  background-color: ${({ theme }) => theme.colors.mainBackground};
  color: ${({ color }) => color};
  padding: 8px;
  border-radius: 8px;
  margin: 10px 0;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  min-width: 115px;
  padding-left: 10px;
`;

export const ClientContainer = styled.div`
  margin-left: 13px;
  /* white-space: nowrap; */
`;

export const DeleteButtonIcon = styled(ButtonIcon)`
  background-color: transparent;
`;
