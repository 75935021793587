import {
  ICustomer,
  ICustomersResponse,
  IPost,
  IPostResponse,
  IPut,
  IPutResponse,
  IResponse,
  SessionContextType,
  TDefaultResponse,
} from 'src/types';

import useFetch from 'src/hooks/useFetch';
import usePost from 'src/hooks/usePost';
import usePut from 'src/hooks/usePut';

interface IPostDeleteCustomer {
  customerId: number;
  force: boolean;
}

export const FetchCustomers = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
): IResponse<ICustomersResponse> => {
  const { isLoading, isFetching, error, data, refetch } = useFetch<ICustomersResponse>({
    key: 'fetch-customers',
    url: '/customer/getAllCustomers',
    headers: { 'x-access-token': token },
    enabled: true,
    onFailure: refreshSession,
  });

  return { isLoading, isFetching, error, data, refetch };
};

export const PostCustomer = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
): IPost<TDefaultResponse<IPostResponse>, ICustomer> => {
  const { isLoading, isError, error, isSuccess, refetch } = usePost<
    TDefaultResponse<IPostResponse>,
    ICustomer
  >({
    key: 'post-customer',
    url: '/customer',
    headers: { 'x-access-token': token },
    onFailure: refreshSession,
  });

  return { isLoading, isError, error, isSuccess, refetch };
};

export const PutCustomer = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
  id: number,
): IPut<TDefaultResponse<IPutResponse>, ICustomer> => {
  const { isLoading, isError, error, isSuccess, refetch } = usePut<
    TDefaultResponse<IPutResponse>,
    ICustomer
  >({
    key: 'put-customer',
    url: `/customer/${id}`,
    headers: { 'x-access-token': token },
    onFailure: refreshSession,
  });

  return { isLoading, isError, error, isSuccess, refetch };
};

export const DeleteCustomer = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
): IPost<TDefaultResponse<IPostResponse>, IPostDeleteCustomer> => {
  const { isLoading, isError, error, isSuccess, refetch } = usePost<
    TDefaultResponse<IPostResponse>,
    IPostDeleteCustomer
  >({
    key: 'delete-customer',
    url: `/customer/remove`,
    headers: { 'x-access-token': token },
    onFailure: refreshSession,
  });

  return { isLoading, isError, error, isSuccess, refetch };
};
