import { IResponse, SessionContextType, THeaders } from 'src/types';
import { UseQueryResult, useQuery, useQueryClient } from 'react-query';

import api from 'src/services/api';

interface IFetch {
  key: string;
  url: string;
  params?: { [key: string]: unknown };
  headers?: THeaders;
  enabled?: boolean;
  onFailure?: SessionContextType['refreshSession'];
}

export default function useFetch<T>({
  key,
  url,
  params = {},
  headers: headersParam,
  onFailure,
  enabled = true,
}: IFetch): IResponse<T> {
  const queryClient = useQueryClient();
  let headers = headersParam;

  const { isLoading, isFetching, error, data, refetch } = useQuery<
    unknown,
    Error,
    UseQueryResult<T, Error>
  >(key, () => api.get(url, { params, headers }), {
    enabled,
    retry: (failureCount, { message }) => {
      if (message?.includes('401')) {
        if (failureCount === 0) {
          onFailure?.()?.then((token) => {
            if (token) {
              headers = {
                ...headersParam,
                'x-access-token': token,
              };
              queryClient.invalidateQueries(key);
            }
          });
        }
        return failureCount < 3;
      }
      return false;
    },
  });

  return { isLoading, isFetching, error, data, refetch };
}
