import styled from 'styled-components';
import Input from 'src/components/Input';
import Button from 'src/components/Button';
import MotoboySelect from 'src/components/MotoboySelect';

export const Grid = styled.div`
  display: grid;
  padding: 10px;
`;

export const Flex = styled.div`
  display: flex;
`;

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 75px);
`;

export const FilterContainer = styled.div`
  width: calc(100% - 20px);
  /* min-width: 1230px; */
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 10px;
  margin: 10px;
  background-color: ${({ theme }) => theme.colors.cardBackground};
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.text};
`;

export const ClientInput = styled(Input).attrs({
  placeholder: 'buscar clientes',
  id: 'client',
  type: 'text',
  autocomplete: 'on',
  margin: '30px 18px 0 0',
  width: '180px',
})`
  font-size: 16px;
`;

export const StartDateInput = styled(Input).attrs({
  placeholder: 'data inicial',
  id: 'motoboy',
  type: 'date',
  autocomplete: 'on',
  margin: '30px 18px 0 0',
  width: '170px',
})`
  font-size: 16px;
  height: 39px;
`;

export const EndDateInput = styled(Input).attrs({
  placeholder: 'data final',
  id: 'motoboy',
  type: 'date',
  autocomplete: 'on',
  margin: '30px 18px 0 0',
  width: '170px',
})`
  font-size: 16px;
  height: 39px;
`;

export const ButtonCard = styled(Flex)`
  margin: 30px 18px 0 0;
`;

export const ExportButton = styled(Button)`
  border-radius: 0;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const ExportCard = styled(Flex)`
  margin: 30px 18px 0 0;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 5px;
  border-radius: 10px;
  height: 40px;
  justify-content: space-around;
  width: 80px;
  align-items: center;
`;

export const Motoboy = styled(MotoboySelect).attrs({
  margin: '30px 18px 0 0',
  width: '280px',
  height: '30px',
  fontSize: '14px',
})``;
