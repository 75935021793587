import {
  AuthParams,
  IPost,
  IRefreshSessionResponse,
  IResponse,
  ISessionResponse,
  TDefaultResponse,
} from 'src/types';

import useFetch from 'src/hooks/useFetch';
import usePost from 'src/hooks/usePost';

export const FetchToken = (): IPost<TDefaultResponse<ISessionResponse>, AuthParams> => {
  const { isLoading, isError, error, isSuccess, refetch } = usePost<
    TDefaultResponse<ISessionResponse>,
    AuthParams
  >({
    key: 'session',
    url: '/owner/authentication',
    retryCount: 0,
  });

  return { isLoading, isError, error, isSuccess, refetch };
};

export const RefreshToken = (
  refreshToken: string,
): IResponse<IRefreshSessionResponse> => {
  const { isLoading, isFetching, error, data, refetch } =
    useFetch<IRefreshSessionResponse>({
      key: 'sessionRefresh',
      url: '/owner/refreshToken',
      headers: { 'x-access-token': refreshToken },
      enabled: false,
    });

  return { isLoading, isFetching, error, data, refetch };
};
