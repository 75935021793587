import {
  AddDeliverymanButton,
  Container,
  DeliverymenInput,
  FilterContainer,
  Flex,
  Grid,
  Label,
} from './styles';
import ConfirmationModal, {
  TConfirmationModalProps,
} from 'src/components/ConfirmationModal';
import {
  DeleteDeliveryman,
  FetchDeliverymen,
  PostDeliveryman,
  PutDeliveryman,
} from 'src/services/deliverymen';
import DeliverymanModal, {
  TDeliverymanModalProps,
} from 'src/components/DeliverymanModal';
import { FaMotorcycle, FaPlus } from 'react-icons/fa';
import InformationModal, {
  TInformationModalProps,
} from 'src/components/InformationModal';
import { debounce, isEqual } from 'lodash';

import DeliverymenTable from 'src/components/DeliverymenTable';
import { IDeliveryman } from 'src/types';
import MainContainer from 'src/components/MainContainer';
import React from 'react';
import { useSession } from 'src/hooks/useSession';
import { useTheme } from 'styled-components';

const Deliverymen = (): JSX.Element => {
  const { colors } = useTheme();
  const [deliveryman, setDeliveryman] = React.useState('');
  const [editedDeliveryman, setEditedDeliveryman] = React.useState<IDeliveryman>(
    {} as IDeliveryman,
  );
  const [deliverymenList, setDeliverymenList] = React.useState<IDeliveryman[]>([]);
  const deliverymenListRef = React.useRef<IDeliveryman[]>([]);

  const [deliverymanModal, setDeliverymanModal] = React.useState<TDeliverymanModalProps>({
    show: false,
    toggleClear: false,
    deliveryman: {} as IDeliveryman,
  });
  const [infoModal, setInfoModal] = React.useState<TInformationModalProps>({
    show: false,
    message: '',
    headerText: '',
  });

  const [confirmationModal, setConfirmationModal] =
    React.useState<TConfirmationModalProps>({
      show: false,
      message: '',
      header: '',
    });

  const [confirmationForceDeleteModal, setConfirmationFroceDeleteModal] =
    React.useState<TConfirmationModalProps>({
      show: false,
      message: '',
      header: '',
    });

  const [deliverymanDeleteId, setDeliverymanDeleteId] = React.useState(0);

  const { session, refreshSession } = useSession();
  const { data, isLoading, isFetching } = FetchDeliverymen(session.token, refreshSession);
  const { isLoading: isPosting, refetch: postDeliveryman } = PostDeliveryman(
    session.token,
    refreshSession,
  );
  const { isLoading: isPuting, refetch: putDeliveryman } = PutDeliveryman(
    session.token,
    refreshSession,
    editedDeliveryman.motoboyId,
  );

  const { isLoading: isDeleting, refetch: deleteDeliveryman } = DeleteDeliveryman(
    session.token,
    refreshSession,
  );

  const filterList = debounce((deliverymanName: string) => {
    if (deliverymanName.length <= 0) {
      setDeliverymenList([...deliverymenListRef.current]);
      return;
    }

    setDeliverymenList(
      deliverymenListRef.current.filter((localDeliveryman) =>
        localDeliveryman.name.toLowerCase().includes(deliverymanName.toLowerCase()),
      ),
    );
  }, 1000);

  const onChangeDeliveryman = React.useCallback(
    (value: string) => {
      setDeliveryman(value);
      filterList(value);
    },
    [filterList],
  );

  const onAddDeliveryman = React.useCallback(
    async (deliverymanToAdd) => {
      setDeliverymanModal((prev) => ({ ...prev, show: false }));
      try {
        const response = await postDeliveryman(deliverymanToAdd);
        if (response) {
          setInfoModal({
            headerText: 'Operação Concluída',
            message: response?.data?.msg && 'Motoqueiro inserido com sucesso',
            show: true,
          });
          setDeliverymanModal((prev) => ({
            toggleClear: !prev.toggleClear,
            show: false,
            deliveryman: {} as IDeliveryman,
          }));
        }
      } catch (error: any) {
        setInfoModal({
          headerText: 'Erro',
          message:
            error?.response?.data?.msg ?? 'Ocorreu um erro ao processar as informações.',
          show: true,
        });
      }
    },
    [postDeliveryman],
  );

  const onEditDeliveryman = React.useCallback(
    async (deliverymanToAdd) => {
      setDeliverymanModal((prev) => ({ ...prev, show: false }));
      try {
        const response = await putDeliveryman(deliverymanToAdd);
        if (response) {
          setInfoModal({
            headerText: 'Operação Concluída',
            message: response?.data?.msg && 'Motoqueiro editado com sucesso',
            show: true,
          });
          setDeliverymanModal((prev) => ({
            toggleClear: !prev.toggleClear,
            show: false,
            deliveryman: {} as IDeliveryman,
          }));
        }
      } catch (error: any) {
        setInfoModal({
          headerText: 'Erro',
          message:
            error?.response?.data?.msg ?? 'Ocorreu um erro ao processar as informações.',
          show: true,
        });
      }
    },
    [putDeliveryman],
  );

  const showDeliverymanModalToEdit = React.useCallback((deliverymanToEdit) => {
    setEditedDeliveryman(deliverymanToEdit);
    setDeliverymanModal((prev) => ({
      ...prev,
      show: true,
      deliveryman: deliverymanToEdit,
    }));
  }, []);

  const onDelete = React.useCallback(async () => {
    setConfirmationModal({ show: false, header: '', message: '' });
    try {
      const response = await deleteDeliveryman({
        motoboyId: deliverymanDeleteId,
        force: false,
      });
      if (response) {
        setInfoModal({
          headerText: 'Operação Concluída',
          message: 'Entregador excluído com sucesso',
          show: true,
        });
        setDeliverymanModal({
          show: false,
          deliveryman: {} as IDeliveryman,
          toggleClear: true,
        });
      }
    } catch (error: any) {
      if (error?.response?.status === 422) {
        setConfirmationFroceDeleteModal({
          show: true,
          header: 'Este motoqueiro já realizou algumas corridas no sistema.',
          message:
            'Esta ação excluirá não só o motoqueiro, mas também todas as suas corridas. Deseja continuar assim mesmo?',
        });
        return;
      }
      setInfoModal({
        headerText: 'Erro',
        message:
          error?.response?.data?.msg ?? 'Ocorreu um erro ao processar as informações.',
        show: true,
      });
    }
  }, [deleteDeliveryman, deliverymanDeleteId]);

  const onForceDelete = React.useCallback(async () => {
    setConfirmationFroceDeleteModal({ show: false, header: '', message: '' });
    try {
      const response = await deleteDeliveryman({
        motoboyId: deliverymanDeleteId,
        force: true,
      });
      if (response) {
        setInfoModal({
          headerText: 'Operação Concluída',
          message: 'Entregador excluído com sucesso',
          show: true,
        });
        setDeliverymanModal({
          show: false,
          deliveryman: {} as IDeliveryman,
          toggleClear: true,
        });
      }
    } catch (error: any) {
      setInfoModal({
        headerText: 'Erro',
        message:
          error?.response?.data?.msg ?? 'Ocorreu um erro ao processar as informações.',
        show: true,
      });
    }
  }, [deleteDeliveryman, deliverymanDeleteId]);

  const showDeleteDeliverymanConfirmation = (deliverymanId: number) => {
    setDeliverymanDeleteId(deliverymanId);

    setConfirmationModal({
      header: 'Excluir entregador',
      message: 'Deseja mesmo excluir esse entregador?',
      show: true,
    });
  };

  React.useEffect(() => {
    if (data?.data?.data) {
      const responseList = data?.data?.data.concat();

      if (isEqual(responseList, deliverymenListRef.current)) {
        return;
      }
      deliverymenListRef.current = data.data?.data.concat();

      filterList(deliveryman);
    }
  }, [data, deliveryman, filterList]);

  return (
    <MainContainer>
      <Container>
        <FilterContainer>
          <Grid>
            <Label>Filtros</Label>
            <Flex>
              <DeliverymenInput
                text={deliveryman}
                icon={<FaMotorcycle color={colors.primary} />}
                onChangeValue={onChangeDeliveryman}
              />
            </Flex>
          </Grid>
          <Grid>
            <AddDeliverymanButton
              iconLeft={<FaPlus color={colors.white} />}
              disabled={isLoading || !!isFetching || isPosting || isPuting || isDeleting}
              onClick={() =>
                setDeliverymanModal((prev) => ({
                  ...prev,
                  show: true,
                }))
              }
            >
              NOVO MOTOQUEIRO
            </AddDeliverymanButton>
          </Grid>
        </FilterContainer>
        <DeliverymenTable
          isLoading={isLoading || !!isFetching || isPosting || isPuting || isDeleting}
          deliverymen={deliverymenList}
          onEditDeliveryman={showDeliverymanModalToEdit}
          onDeleteDeliveryman={showDeleteDeliverymanConfirmation}
        />
      </Container>
      <DeliverymanModal
        show={deliverymanModal.show}
        clearDeliveryman={deliverymanModal.toggleClear}
        onDismiss={() =>
          setDeliverymanModal((prev) => ({
            ...prev,
            show: false,
            deliveryman: {} as IDeliveryman,
          }))
        }
        onAdd={onAddDeliveryman}
        onEdit={onEditDeliveryman}
        currentDeliveryman={deliverymanModal.deliveryman}
      />
      <InformationModal
        headerText={infoModal.headerText}
        show={infoModal.show}
        onDismiss={() => setInfoModal({ headerText: '', message: '', show: false })}
        message={infoModal.message}
      />
      <ConfirmationModal
        header={confirmationModal.header}
        message={confirmationModal.message}
        show={confirmationModal.show}
        onCancel={() => setConfirmationModal({ show: false, header: '', message: '' })}
        onConfirm={onDelete}
      />
      <ConfirmationModal
        header={confirmationForceDeleteModal.header}
        message={confirmationForceDeleteModal.message}
        show={confirmationForceDeleteModal.show}
        onCancel={() =>
          setConfirmationFroceDeleteModal({ show: false, header: '', message: '' })
        }
        onConfirm={onForceDelete}
      />
    </MainContainer>
  );
};

export default Deliverymen;
