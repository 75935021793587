import {
  IPut,
  IPutEmail,
  IPutForgotPassword,
  IPutPassword,
  IPutResponse,
  SessionContextType,
  TDefaultResponse,
} from 'src/types';

import usePut from 'src/hooks/usePut';

export const PutPassword = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
): IPut<TDefaultResponse<IPutResponse>, IPutPassword> => {
  const { isLoading, isError, error, isSuccess, refetch } = usePut<
    TDefaultResponse<IPutResponse>,
    IPutPassword
  >({
    key: 'put-password',
    url: '/owner/updatePassword',
    headers: { 'x-access-token': token },
    onFailure: refreshSession,
  });

  return { isLoading, isError, error, isSuccess, refetch };
};

export const PutEmail = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
): IPut<TDefaultResponse<IPutResponse>, IPutEmail> => {
  const { isLoading, isError, error, isSuccess, refetch } = usePut<
    TDefaultResponse<IPutResponse>,
    IPutEmail
  >({
    key: 'put-email',
    url: '/owner/updateEmail',
    headers: { 'x-access-token': token },
    onFailure: refreshSession,
  });

  return { isLoading, isError, error, isSuccess, refetch };
};

export const PutForgotPassword = (): IPut<
  TDefaultResponse<IPutResponse>,
  IPutForgotPassword
> => {
  const { isLoading, isError, error, isSuccess, refetch } = usePut<
    TDefaultResponse<IPutResponse>,
    IPutForgotPassword
  >({
    key: 'forgot-password',
    url: '/owner/forgotPassword',
  });

  return { isLoading, isError, error, isSuccess, refetch };
};
