import {
  AuthForm,
  Container,
  ForgotButton,
  LoaderContainer,
  LoginButton,
  LoginContainer,
  LoginDescription,
  LoginInput,
  PasswordInput,
  TitleContainer,
} from './styles';
import { FaLock, FaRegUser } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';

import { AuthParams } from 'src/types';
import Loader from 'src/components/Loader';
import { ReactComponent as Logo } from 'src/assets/svg/logo.svg';
import React from 'react';
import { useSession } from 'src/hooks/useSession';
import { useTheme } from 'styled-components';

const Login = (): JSX.Element => {
  const [{ email, password }, setCredentials] = React.useState<AuthParams>({
    email: '',
    password: '',
  });
  const [error, setError] = React.useState<string>('');
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();

  const { isLoading, login } = useSession();

  const onChangeEmail = React.useCallback((value: string) => {
    setError('');
    setCredentials((prev) => ({ ...prev, email: value }));
  }, []);

  const onChangePassword = React.useCallback((value: string) => {
    setError('');
    setCredentials((prev) => ({ ...prev, password: value }));
  }, []);

  const onSubmit = React.useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      try {
        await login(email, password);
        const { from }: any = location.state || { from: { pathname: '/' } };
        history.replace(from);
      } catch (e: any) {
        if (e?.message?.includes('401')) {
          setError('Usuário e/ou senha incorretos');
        } else if (e?.message?.includes('500')) {
          setError('Ocorreu com o servidor.\nEntre em contato com o adminstrador.');
        } else {
          setError('Ocorreu um erro desconhecido.\nEntre em contato com o adminstrador.');
        }
      }
    },
    [email, history, location.state, login, password],
  );

  return (
    <Container>
      <TitleContainer>
        <Logo />
      </TitleContainer>
      <AuthForm onSubmit={onSubmit} noValidate>
        <LoginContainer>
          <LoginDescription>Área restrita.</LoginDescription>
          <LoginDescription>Por favor, realize o login para continuar.</LoginDescription>
        </LoginContainer>
        <LoginInput text={email} icon={<FaRegUser />} onChangeValue={onChangeEmail} />
        <PasswordInput
          text={password}
          icon={<FaLock />}
          onChangeValue={onChangePassword}
        />
        <LoginButton disabled={isLoading} type="submit">
          ENTRAR
        </LoginButton>
        <ForgotButton to="/forgotPassword" type="button">
          ESQUECI A SENHA
        </ForgotButton>
        <LoaderContainer>
          {isLoading ? (
            <Loader color={theme.colors.primary} />
          ) : (
            <LoginDescription>{error}</LoginDescription>
          )}
        </LoaderContainer>
      </AuthForm>
    </Container>
  );
};

export default Login;
