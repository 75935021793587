import React from 'react';
import styled, { useTheme } from 'styled-components';

interface Props {
  pay: boolean;
}

interface ContentStyle {
  bgColor: string;
}

const Content = styled.div<ContentStyle>`
  padding: 8px 4px;
  width: auto;
  border-radius: 8px;
  background-color: ${({ bgColor }) => bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 90px;
`;

const PayText = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const PayDelivery: React.FC<Props> = ({ pay }) => {
  const { colors } = useTheme();

  return (
    <Content bgColor={pay ? colors.approve : colors.textError}>
      <PayText>{pay ? 'PAGO' : 'NÃO PAGO'}</PayText>
    </Content>
  );
};

export default PayDelivery;
