import {
  Backdrop,
  Body,
  CloseButton,
  CloseIcon,
  CostLabel,
  Column,
  Container,
  Content,
  Header,
  HeaderText,
  IconMotoboy,
  Line,
  ModalContainer,
  StyledModal,
  RowHistoric,
  DeliveryNumber,
  StatusContainer,
  IsEditedIcon,
  IsDeletedIcon,
  ClientContainer,
  InfoContainer,
  CustomerIcon,
  MotoboyIcon,
  Label,
  DescriptionContainer,
  DateContainer,
} from './styles';

import { IReceipts } from 'src/types';
import PaymentType from '../PaymentType';
import React from 'react';
import ReactDOM from 'react-dom';
import { isEmpty } from 'lodash';
import { dateTimeFormat } from 'src/utils/DateFormatter';
import { useTheme } from 'styled-components';
import { maskToPrice } from 'src/utils/InputMask';

export interface ShowReceiptModal {
  show: boolean;
  receipt: IReceipts;
}

interface Props {
  receipt: IReceipts;
  show: boolean;
  onDismiss(): void;
}

const DeliveryHistoricModal: React.FC<Props> = ({ receipt, show, onDismiss }) => {
  const [editReceipt, setEditReceipt] = React.useState<IReceipts>({} as IReceipts);
  const { colors } = useTheme();

  React.useEffect(() => {
    setEditReceipt(receipt);
  }, [receipt]);

  React.useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      const key = event.key || event.keyCode;
      if ((key === 27 || key === 'Escape') && show) {
        onDismiss();
      }
    };

    show
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');

    document.addEventListener('keydown', onKeyDown, false);

    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [onDismiss, show]);

  const StatusIcon = (isEdited: boolean, isDeleted: boolean) => {
    const showIcon = isDeleted || isEdited;

    return (
      showIcon && (
        <StatusContainer color={isEdited ? colors.secondary : colors.textError}>
          {isEdited && !isDeleted && <IsEditedIcon />}
          {isDeleted && <IsDeletedIcon />}
        </StatusContainer>
      )
    );
  };

  const RowGenerate = ({
    isEdited,
    isDeleted,
    customer,
    motoboy,
    deliveryId,
    description,
    date,
    paymentType,
    cost,
  }: IReceipts) => (
    <RowHistoric>
      <Line>
        <DeliveryNumber color={isEdited || isDeleted ? colors.textLight : colors.primary}>
          {deliveryId}
          {StatusIcon(isEdited, isDeleted)}
        </DeliveryNumber>
        <ClientContainer>
          <InfoContainer>
            <CustomerIcon
              color={isEdited || isDeleted ? colors.textLight : colors.primary}
            />
            <Label color={isEdited || isDeleted ? colors.textLight : colors.text}>
              {customer.name}
            </Label>
          </InfoContainer>
          <InfoContainer>
            <MotoboyIcon color={isEdited || isDeleted ? colors.textLight : colors.text} />
            <Label color={isEdited || isDeleted ? colors.textLight : colors.text}>
              {motoboy.name}
            </Label>
          </InfoContainer>
        </ClientContainer>
      </Line>
      <DescriptionContainer
        color={isEdited || isDeleted ? colors.textLight : colors.text}
      >
        {description}
      </DescriptionContainer>
      <DateContainer color={isEdited || isDeleted ? colors.textLight : colors.text}>
        {dateTimeFormat(new Date(date))}
      </DateContainer>
      <PaymentType
        color={isEdited || isDeleted ? colors.mainBackground : colors.secondary}
        disabled={isDeleted || isEdited}
        value={paymentType}
        onChange={() => null}
        disabledButton
      />
      <CostLabel color={isEdited || isDeleted ? colors.textLight : colors.primary}>
        R$ {maskToPrice(cost.toString()).replace('.', ',')}
      </CostLabel>
    </RowHistoric>
  );

  const modal = !isEmpty(editReceipt) && (
    <Container>
      <Backdrop onClick={onDismiss} />
      <ModalContainer aria-modal tabIndex={-1} role="dialog">
        <StyledModal>
          <Header>
            <HeaderText>
              <IconMotoboy />
              HISTÓRICO DA CORRIDA N° {receipt.deliveryId}
            </HeaderText>
            <CloseButton onClick={onDismiss}>
              <CloseIcon />
            </CloseButton>
          </Header>
          <Content>
            <Body>
              <Column>
                {RowGenerate(editReceipt)}
                {editReceipt.historic?.map((r) => RowGenerate(r))}
              </Column>
            </Body>
          </Content>
        </StyledModal>
      </ModalContainer>
    </Container>
  );
  return show ? ReactDOM.createPortal(modal, document.body) : null;
};

export default DeliveryHistoricModal;
