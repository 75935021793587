import styled from 'styled-components';
import Button from '../Button';

export const Container = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const PaymentButton = styled(Button)`
  background-color: ${(props) => props.color};
  padding: 5px;
  :disabled {
    cursor: default;
  }
`;
