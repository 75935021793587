import styled, { css } from 'styled-components';

export const IconRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
`;

export const IconLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(0, -50%);
`;

export const ButtonObj = styled.button`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 10px;
  text-align: center;
  border: transparent;
  background: ${({ theme }) => theme.colors.secondary};

  border-radius: 49px;
  color: ${({ theme }) => theme.colors.white};

  font-style: normal;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.white};

  transition-duration: 0.2s;

  ${(props) => {
    return props.disabled
      ? css`
          opacity: 0.8;
          cursor: not-allowed;
        `
      : css`
          &:hover {
            cursor: pointer;
            opacity: 0.8;
          }
        `;
  }};
`;
