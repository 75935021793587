import styled from 'styled-components';
import Button from 'src/components/Button';

export const InvoiceStatusButton = styled(Button)`
  background-color: ${(props) => props.color};
  padding: 5px;
  :disabled {
    cursor: default;
  }
`;

export const Container = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

interface ILabelButton {
  color: string;
}

export const LabelButton = styled.label<ILabelButton>`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ color }) => color};
  padding: 3px 10px;
  width: 70px;
  cursor: pointer;
`;
