import {
  CardBody,
  CardDescription,
  CardForm,
  CardTitle,
  ConfirmPasswordInput,
  EmailCard,
  EmailInput,
  ErrorDescription,
  Grid,
  LoaderContainer,
  NewPasswordInput,
  NewPsswordStrength,
  PasswordCard,
  PasswordInput,
  PasswordStrengthBar,
  ProfileCard,
  ProfileContainer,
  SubmitButton,
  UserMail,
  UserName,
} from './styles';
import { FaLock, FaUserCircle } from 'react-icons/fa';
import { PutEmail, PutPassword } from 'src/services/user';

import InformationModal from 'src/components/InformationModal';
import { IoMail } from 'react-icons/io5';
import Loader from 'src/components/Loader';
import MainContainer from 'src/components/MainContainer';
import React from 'react';
import { useSession } from 'src/hooks/useSession';
import { useTheme } from 'styled-components';

interface ChangePasswordParams {
  password: string;
  newPassword: string;
  confirmNewPassword: string;
}

const MyData = (): JSX.Element => {
  const { colors } = useTheme();
  const {
    session: { user },
  } = useSession();
  const [{ password, newPassword, confirmNewPassword }, setNewPassword] =
    React.useState<ChangePasswordParams>({
      password: '',
      newPassword: '',
      confirmNewPassword: '',
    });
  const [passwordError, setPasswordError] = React.useState<string>('');
  const [passwordStrength, setPasswordStrength] = React.useState<number>(0);

  const [isPasswordLoading, setPasswordLoading] = React.useState<boolean>(false);
  const [isEmailLoading, setEmailLoading] = React.useState<boolean>(false);
  const [emailError, setEmailError] = React.useState<string>('');
  const [newEmail, setNewEmail] = React.useState<string>('');

  const { session, refreshSession } = useSession();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [modalMessage, setModalMessage] = React.useState<string>('');
  const { isLoading: isPuttingPassword, refetch: putPassword } = PutPassword(
    session?.token,
    refreshSession,
  );
  const { isLoading: isPuttingEmail, refetch: putEmail } = PutEmail(
    session?.token,
    refreshSession,
  );

  const onChangePassword = (value: string) => {
    setPasswordError('');
    setNewPassword({ password: value, newPassword, confirmNewPassword });
  };

  const onChangeNewPassword = (value: string) => {
    setPasswordError('');
    const trimmedValue = value.trim();
    let strength = 0;
    if (trimmedValue.length >= 8) {
      strength += 1;
    }
    if (/\d/.test(trimmedValue)) {
      strength += 1;
    }

    // Caracteres especiais
    // if (/[`!@#$%ˆ^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/.test(trimmedValue)) {
    if (/[a-zA-Z]/.test(trimmedValue)) {
      strength += 1;
    }

    setPasswordStrength(strength);
    setNewPassword({ password, newPassword: value, confirmNewPassword });
  };

  const onChangeConfirmNewPassword = (value: string) => {
    setPasswordError('');
    setNewPassword({ password, newPassword, confirmNewPassword: value });
  };

  const onChangePasswordSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setPasswordLoading(true);

    if (password.length === 0) {
      setPasswordError('Favor informar a senha atual.');
      setPasswordLoading(false);
      return;
    }

    if (
      newPassword.length < 8 ||
      !/\d/.test(newPassword) ||
      !/[a-zA-Z]/.test(newPassword)
    ) {
      setPasswordError('A nova senha não cumpre os requisitos mínimos de segurança.');
      setPasswordLoading(false);
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setPasswordError('A nova senha deve ser igual à confirmação da senha.');
      setPasswordLoading(false);
      return;
    }

    try {
      const response = await putPassword({ password, newPassword });
      if (response?.status === 200) {
        setModalMessage(response.data.msg ?? 'Sua senha foi alterada com sucesso');
        setPasswordStrength(0);
        setNewPassword({ password: '', newPassword: '', confirmNewPassword: '' });
      } else {
        setModalMessage(response.data.msg ?? 'Ocorreu um erro ao atualizar a senha');
      }
      setShowModal(true);
    } catch (error: any) {
      if (error.response.status === 401) {
        return;
      }
      setModalMessage(error.response.data.msg ?? 'Ocorreu um erro ao atualizar a senha.');
      setShowModal(true);
    }

    setPasswordLoading(false);
  };

  const onChangeEmail = (value: string) => {
    setEmailError('');
    setNewEmail(value.trim());
  };

  const onChangeEmailSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setEmailLoading(true);

    if (newEmail.length === 0) {
      setEmailError('Favor informar o e-mail.');
      setEmailLoading(false);
      return;
    }

    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(String(newEmail).toLowerCase())) {
      setEmailError('E-mail inválido.');
      setEmailLoading(false);
      return;
    }

    try {
      const response = await putEmail({ email: user?.email, newEmail });

      if (response?.status === 200) {
        setModalMessage(response.data.msg ?? 'Seu e-mail foi alterada com sucesso');
        setNewEmail('');
      } else {
        setModalMessage(response.data.msg ?? 'Ocorreu um erro ao atualizar o email');
      }
      setShowModal(true);
      refreshSession();
    } catch (error: any) {
      if (error.response.status === 401) {
        return;
      }
      setModalMessage(error.response.data.msg ?? 'Ocorreu um erro ao atualizar o email');
      setShowModal(true);
    }

    setEmailLoading(false);
  };

  return (
    <MainContainer>
      <Grid>
        <ProfileCard>
          <FaUserCircle color={colors.text} size={120} />
          <ProfileContainer>
            <UserName>{user?.name?.toLocaleUpperCase()}</UserName>
            <UserMail>{user?.email?.toLocaleUpperCase()}</UserMail>
          </ProfileContainer>
        </ProfileCard>
        <PasswordCard>
          <CardTitle>TROCA DE SENHA</CardTitle>
          <CardForm onSubmit={onChangePasswordSubmit} noValidate id="password-form">
            <CardDescription>
              Faça a troca da senha de acesso ao sistema. A senha deve conter no mínimo 8
              caracteres, contendo letras e números simultâneamente. O nível da sua senha
              é indicado pela barra verde e o ícone de confirmação.
            </CardDescription>
            <CardBody>
              <PasswordInput
                text={password}
                icon={<FaLock color={colors.primary} />}
                onChangeValue={onChangePassword}
              />
              <NewPasswordInput
                text={newPassword}
                icon={<FaLock color={colors.secondary} />}
                onChangeValue={onChangeNewPassword}
              />
              <NewPsswordStrength>
                <PasswordStrengthBar isVisible={passwordStrength >= 1} />
                <PasswordStrengthBar isVisible={passwordStrength >= 2} />
                <PasswordStrengthBar isVisible={passwordStrength >= 3} />
              </NewPsswordStrength>
              <ConfirmPasswordInput
                text={confirmNewPassword}
                icon={<FaLock color={colors.secondary} />}
                onChangeValue={onChangeConfirmNewPassword}
              />
              <SubmitButton
                disabled={isPasswordLoading || isPuttingPassword}
                type="submit"
              >
                TROCAR SENHA
              </SubmitButton>
              <LoaderContainer>
                {isPasswordLoading || isPuttingPassword ? (
                  <Loader color={colors.primary} />
                ) : (
                  <ErrorDescription>{passwordError}</ErrorDescription>
                )}
              </LoaderContainer>
            </CardBody>
          </CardForm>
        </PasswordCard>
        <EmailCard>
          <CardTitle>TROCA DE EMAIL</CardTitle>
          <CardForm onSubmit={onChangeEmailSubmit} noValidate>
            <CardDescription>
              O e-mail garante o seu acesso ao sistema. Cuidado ao trocar o seu e-mail,
              caso perca a sua senha ele será utilizado para recuperá-la.
            </CardDescription>
            <CardBody>
              <EmailInput
                text={newEmail}
                icon={<IoMail color={colors.primary} />}
                onChangeValue={onChangeEmail}
              />

              <SubmitButton disabled={isEmailLoading || isPuttingEmail} type="submit">
                TROCAR E-MAIL
              </SubmitButton>
              <LoaderContainer>
                {isEmailLoading || isPuttingEmail ? (
                  <Loader color={colors.primary} />
                ) : (
                  <ErrorDescription>{emailError}</ErrorDescription>
                )}
              </LoaderContainer>
            </CardBody>
          </CardForm>
        </EmailCard>
      </Grid>
      <InformationModal
        headerText=""
        show={showModal}
        onDismiss={() => setShowModal(false)}
        message={modalMessage}
      />
    </MainContainer>
  );
};

export default MyData;
