import { IoBriefcaseSharp } from 'react-icons/io5';
import { FaBriefcase } from 'react-icons/fa';
import styled from 'styled-components';
import Button from '../Button';

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.text};
`;

export const CellContainer = styled(FlexCenter)`
  position: relative;
  flex: 1;
  text-align: left;
  justify-content: flex-start;
`;

export const Bold = styled(CellContainer)`
  justify-content: flex-start;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const UserIcon = styled(FaBriefcase)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.primary};
`;

export const EditButtonIcon = styled(Button).attrs({
  disabled: true,
})`
  background-color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  right: 20px;
  border-radius: 5px;
  padding: 8px;
`;

export const PayButtonIcon = styled(Button)`
  cursor: pointer;
  position: absolute;
  right: 20px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  background-color: ${({ theme }) => theme.colors.textError};
  border-radius: 5px;
  padding: 8px;
`;

export const ApproveButtonIcon = styled(Button).attrs({
  disabled: true,
})`
  position: absolute;
  right: 20px;
  background-color: ${({ theme }) => theme.colors.approve};
  border-radius: 5px;
  padding: 8px;
`;

export const EmpytContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.mainBackground};
  width: 100%;
  height: 100%;
`;

export const EmptyMessage = styled.div`
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.text};
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 3px;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.white};
`;

export const CustomerIcon = styled(IoBriefcaseSharp)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin-right: 10px;
  color: ${({ color }) => color};
`;

export const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  color: ${({ color }) => color};
`;
