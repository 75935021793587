import { FaBriefcase, FaEdit, FaLock, FaTrashAlt } from 'react-icons/fa';
import styled, { css } from 'styled-components';

type CellProps = {
  enabled: boolean;
};

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.text};
`;

export const CellContainer = styled(FlexCenter)<CellProps>`
  flex: 1;
  ${({ enabled }) =>
    !enabled &&
    css`
      color: ${({ theme }) => theme.colors.disabled};
    `}
`;

export const CellStartContainer = styled(FlexCenter)`
  flex: 1;
  justify-content: flex-start;
`;

export const Bold = styled(CellContainer)`
  justify-content: flex-start;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const IconContainer = styled(FlexCenter)`
  padding: 10px;
  margin-right: 10px;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 10px;
`;

export const UserIcon = styled(FaBriefcase)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.primary};
`;

export const LockIcon = styled(FaLock)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.disabled};
`;

export const EditIcon = styled(FaEdit)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin: 6px;
  color: ${({ theme }) => theme.colors.white};
`;

export const TrashIcon = styled(FaTrashAlt)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin: 0 10px 0;
  color: ${({ theme }) => theme.colors.primary};
`;

export const EditButtonIcon = styled(FlexCenter)`
  cursor: pointer;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 5px;
  height: 30px;
  width: 30px;
`;

export const DeleteButtonIcon = styled(EditButtonIcon)`
  background-color: transparent;
  margin-left: 10px;
`;

export const EmpytContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.mainBackground};
  width: 100%;
  height: 100%;
`;

export const EmptyMessage = styled.div`
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.text};
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
`;
