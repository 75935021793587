import React from 'react';
import { IFetchPendencies } from 'src/types';
import {
  Backdrop,
  Body,
  Container,
  Content,
  Header,
  HeaderText,
  Line,
  Row,
  StyledModal,
  Description,
  Date,
  CloseButton,
  CloseIcon,
  TrashIcon,
  ButtonIcon,
} from './styles';
import ReactDOM from 'react-dom';
import { monthFormat } from 'src/utils/DateFormatter';
import { DeletePendency } from 'src/services/motoboy-pendencies';
import { useSession } from 'src/hooks/useSession';

interface Props {
  pendencies: IFetchPendencies[];
  show: boolean;
  onDismiss: () => void;
}

const PendencyListModal: React.FC<Props> = ({ pendencies, show, onDismiss }) => {
  const { session, refreshSession } = useSession();
  const [removePendencyId, setRemovePendencyId] = React.useState(-1);

  const { refetch: remove } = DeletePendency(
    session.token,
    refreshSession,
    removePendencyId,
  );

  React.useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      const key = event.key || event.keyCode;
      if ((key === 27 || key === 'Escape') && show) {
        onDismiss();
      }
    };

    show
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');

    document.addEventListener('keydown', onKeyDown, false);
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [onDismiss, show]);

  React.useEffect(() => {
    if (removePendencyId > 0) {
      const onDelete = async () => {
        await remove(null);
        onDismiss();
        setRemovePendencyId(-1);
      };

      onDelete();
    }
  }, [onDismiss, remove, removePendencyId]);

  const onRemovePendency = React.useCallback((pendencyId: number) => {
    setRemovePendencyId(pendencyId);
  }, []);

  const modal = (
    <>
      <Backdrop />
      <Container aria-modal aria-labelledby="Pendências" tabIndex={-1} role="dialog">
        <StyledModal>
          <Header>
            <HeaderText>Pendências</HeaderText>
            <CloseButton onClick={onDismiss}>
              <CloseIcon />
            </CloseButton>
          </Header>
          <Line />
          <Body>
            <Content>
              {pendencies.map((e) => (
                <Row key={e.id}>
                  <Description>{e.description}</Description>
                  <Date>
                    {monthFormat(e.referenceMonth)}/{e.referenceYear}
                    <ButtonIcon onClick={() => onRemovePendency(e.id)}>
                      <TrashIcon />
                    </ButtonIcon>
                  </Date>
                </Row>
              ))}
            </Content>
          </Body>
        </StyledModal>
      </Container>
    </>
  );

  return show ? ReactDOM.createPortal(modal, document.body) : null;
};

export default PendencyListModal;
