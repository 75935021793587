import { Container, Label } from './styles';

import React from 'react';

interface Props {
  value: boolean | undefined;
  disabled?: boolean;
  fontSize?: string;
  onChangeValue?(value: boolean): void;
}

const YesNoSelector: React.FC<Props> = ({
  value,
  disabled = false,
  onChangeValue,
  fontSize,
}) => {
  const [state, setState] = React.useState<boolean | undefined>(value);

  const changeState = React.useCallback(
    (newState: boolean) => {
      if (disabled) return;
      onChangeValue?.(newState);
      setState(newState);
    },
    [disabled, onChangeValue],
  );

  React.useEffect(() => {
    setState(value);
  }, [value]);

  return (
    <Container>
      <Label
        fontSize={fontSize}
        disabled={disabled}
        selected={!!state}
        onClick={() => changeState(true)}
      >
        Sim
      </Label>
      <Label
        fontSize={fontSize}
        disabled={disabled}
        selected={state === null || state === undefined ? false : !state}
        onClick={() => changeState(false)}
      >
        Não
      </Label>
    </Container>
  );
};

export default YesNoSelector;
