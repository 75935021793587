import {
  ActionsContainer,
  Bold,
  CellContainer,
  CellStartContainer,
  DeleteButtonIcon,
  EditButtonIcon,
  EditIcon,
  EmptyMessage,
  EmpytContainer,
  IconContainer,
  LockIcon,
  TrashIcon,
  UserIcon,
} from './styles';
import DataTable, { createTheme } from 'react-data-table-component';

import { ICustomer } from 'src/types';
import Loader from 'src/components/Loader';
import React from 'react';
import { maskToCnpj } from 'src/utils/InputMask';
import { useTheme } from 'styled-components';

interface Props {
  customers?: ICustomer[];
  isLoading: boolean;
  onEditCustomer(customer: ICustomer): void;
  onDeleteCustomer(customerId: number): void;
}

createTheme('jucaTheme', {
  text: {
    primary: '#FFFFFF',
    secondary: 'rgba(255, 255, 255, 0.7)',
    disabled: 'rgba(0,0,0,.12)',
  },
  background: {
    default: '#424242',
  },
  context: {
    background: '#E91E63',
    text: '#FFFFFF',
  },
  divider: {
    default: 'transparent',
  },
  button: {
    default: '#FFFFFF',
    focus: 'rgba(255, 255, 255, .54)',
    hover: 'rgba(255, 255, 255, .12)',
    disabled: 'rgba(255, 255, 255, .18)',
  },
  selected: {
    default: 'rgba(0, 0, 0, .7)',
    text: '#FFFFFF',
  },
  highlightOnHover: {
    default: 'rgba(0, 0, 0, .7)',
    text: '#FFFFFF',
  },
  striped: {
    default: 'rgba(0, 0, 0, .87)',
    text: '#FFFFFF',
  },
});

const CustomersTable: React.FC<Props> = ({
  customers,
  isLoading,
  onEditCustomer,
  onDeleteCustomer,
}) => {
  const { colors } = useTheme();

  const onEdit = React.useCallback(
    (e: React.MouseEvent, customer: ICustomer) => {
      e.preventDefault();
      onEditCustomer(customer);
    },
    [onEditCustomer],
  );

  const onDelete = React.useCallback(
    (e: React.MouseEvent, customer: ICustomer) => {
      e.preventDefault();
      onDeleteCustomer(customer.customerId);
    },
    [onDeleteCustomer],
  );

  const columns = React.useMemo(
    () => [
      {
        name: 'Nome',
        cell: ({ name, enable }: ICustomer) => (
          <CellStartContainer>
            <IconContainer>{enable ? <UserIcon /> : <LockIcon />}</IconContainer>
            <Bold enabled={enable}>{name}</Bold>
          </CellStartContainer>
        ),
      },
      {
        name: 'Telefone',
        cell: ({ phone, enable }: ICustomer) => (
          <CellContainer enabled={enable}>{phone}</CellContainer>
        ),
      },
      {
        name: 'Email',
        cell: ({ email, enable }: ICustomer) => (
          <CellContainer enabled={enable}>{email.toLocaleLowerCase()}</CellContainer>
        ),
      },
      {
        // TODO: Mask to CNPJ
        name: 'CNPJ',
        cell: ({ cnpj, enable }: ICustomer) => (
          <CellContainer enabled={enable}>
            cnpj: {maskToCnpj(cnpj.toLocaleLowerCase())}
          </CellContainer>
        ),
      },
      {
        name: 'Actions',
        cell: (customer: ICustomer) => (
          <ActionsContainer>
            <EditButtonIcon onClick={(event) => onEdit(event, customer)}>
              <EditIcon />
            </EditButtonIcon>
            <DeleteButtonIcon onClick={(event) => onDelete(event, customer)}>
              <TrashIcon color={colors.white} />
            </DeleteButtonIcon>
          </ActionsContainer>
        ),
        width: '100px',
      },
    ],
    [colors.white, onDelete, onEdit],
  );

  const customStyle = React.useMemo(
    () => ({
      table: {
        style: {
          padding: '0 10px 0',
          backgroundColor: colors.mainBackground,
          color: colors.text,
        },
      },
      rows: {
        style: {
          fontSize: '13px',
          color: colors.text,
          backgroundColor: colors.cardBackground,
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
          minHeight: '60px',
          margin: '8px 0 8px',
          borderRadius: '10px',
        },
      },
      pagination: {
        style: {
          backgroundColor: colors.mainBackground,
          color: colors.white,
        },
      },
    }),
    [colors],
  );

  return (
    <DataTable
      subHeader={false}
      noHeader
      noTableHead
      responsive
      pagination
      style={{
        display: 'flex',
        alignItems: customers && customers.length > 0 ? 'flex-start' : 'center',
        justifyContent: 'center',
        height: 'calc(100% - 208px)',
        overflow: 'auto',
      }}
      paginationComponentOptions={{
        rowsPerPageText: 'Quantidade de linhas:',
        rangeSeparatorText: 'Total de Registros:',
        noRowsPerPage: true,
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
      }}
      columns={columns}
      data={customers == null ? [] : customers}
      theme="jucaTheme"
      customStyles={customStyle}
      noDataComponent={
        <EmpytContainer>
          {isLoading ? (
            <Loader color={colors.primary} />
          ) : (
            <EmptyMessage>Lista vazia</EmptyMessage>
          )}
        </EmpytContainer>
      }
    />
  );
};

export default CustomersTable;
