import { Body, Container } from './styles';

import MainMenu from 'src/components/MainMenu';
import React from 'react';
import TopBar from 'src/components/TopBar';

const MainContainer: React.FC = ({ children }) => {
  return (
    <Container>
      <TopBar />
      <Body>
        <MainMenu />
        {children}
      </Body>
    </Container>
  );
};

export default MainContainer;
