import { DefaultTheme } from 'styled-components';

export const lightTheme: DefaultTheme = {
  colors: {
    primaryAlpha: 'rgba(255, 204, 0, 0.9)',
    primary: '#FFCC00',
    secondary: '#0A02ED',
    text: '#fff',
    textLight: '#5D6468',
    textError: '#E50601',
    white: '#fff',
    background: '#17191C',
    cardBackground: '#2F3438',
    mainBackground: '#25292E',
    border: '#e1e1e1',
    approve: '#00FF0A',
    disabled: '#C4C4C4',
  },
  fontSizes: {
    small: '12px',
    medium: '16px',
    large: '20px',
    cardTitle: '20px',
    cardBody: '90px',
    profileTitle: '50px',
    ultraLarge: '30px',
  },
  fontWeights: {
    light: '300',
    medium: '400',
    normal: '500',
    bold: '600',
    extraBold: 'bold',
  },
  spacings: {
    verySmall: '3px',
    small: '6px',
    medium: '12px',
    large: '24px',
  },
  cornerRadius: {
    small: '4px',
    medium: '8px',
    large: '16px',
  },
  iconSizes: {
    iconActiveSize: 26,
    iconUnactiveSize: 22,
  },
  screensWidth: {
    mobile: '576px',
  },
  barTheme: {
    background: '#2F3438',
    textColor: '#A8A8A8',
    fontSize: 13,
    axis: {
      domain: {
        line: {
          stroke: '#777777',
          strokeWidth: 0,
        },
      },
      ticks: {
        line: {
          stroke: '#777777',
          strokeWidth: 0,
        },
      },
    },
    grid: {
      line: {
        stroke: '#dddddd',
        strokeWidth: 0,
      },
    },
  },
};

export const darkTheme = lightTheme;

export default lightTheme;
