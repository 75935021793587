import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface ContainerProps {
  open: boolean;
}

interface LinkProps {
  open: boolean;
  selected: boolean;
}

export const Container = styled.nav<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
  text-align: left;
  transition: transform 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  width: ${({ open }) => (open ? 340 : 50)}px;
`;

export const Button = styled(Link)<LinkProps>`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 46px;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.secondary : theme.colors.background};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  padding: 9px;
  margin: 0 0 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;

export const Text = styled.div<LinkProps>`
  color: ${({ selected, theme }) =>
    selected ? theme.colors.white : theme.colors.textLight};
  opacity: ${({ open }) => (open ? 1 : 0)};
  width: ${({ open }) => (open ? '100%' : 0)};
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  transition: visibility 0s, opacity 0.3s ease-in-out, width 0.3s ease-in-out;
`;

export const PinkText = styled.div<ContainerProps>`
  color: ${(props) => props.theme.colors.primary};
  opacity: ${({ open }) => (open ? 1 : 0)};
  width: ${({ open }) => (open ? '100%' : 0)};
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  transition: visibility 0s, opacity 0.3s ease-in-out, width 0.3s ease-in-out;
`;
