import Button from 'src/components/Button';
import { FaExclamationCircle } from 'react-icons/fa';
import styled from 'styled-components';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const Message = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.large};
  padding: 20px 20px 40px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
`;
export const DismissButton = styled(Button)`
  width: 100px;
  text-align: center;
  background: transparent;
  color: ${({ theme }) => theme.colors.primary};
  border: 2px solid ${({ theme }) => theme.colors.primary};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 49px;
`;

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  width: inherit;
  outline: 0;
`;

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(23, 25, 28, 0.9);
  z-index: 500;
`;

export const StyledModal = styled.div`
  z-index: 100;
  background: white;
  position: relative;
  margin: auto;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors.mainBackground};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
`;

export const Header = styled.div`
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.mainBackground};
`;

export const HeaderText = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.text};
`;

export const Content = styled.div`
  padding: 10px;
  max-height: 30rem;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const ErrorIcon = styled(FaExclamationCircle)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin: 0 10px 0;
  color: ${({ theme }) => theme.colors.text};
`;
