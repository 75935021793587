import styled, { css } from 'styled-components';
import { FaCalendar, FaMotorcycle } from 'react-icons/fa';
import { IoCloseCircleOutline } from 'react-icons/io5';
import Button from 'src/components/Button';
import Input from '../Input';

interface InputError {
  error: boolean;
}

export const StyledModal = styled.div`
  z-index: 100;
  background: ${({ theme }) => theme.colors.cardBackground};
  position: relative;
  margin: auto;
  border-radius: 15px;
`;

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ModalContainer = styled.div`
  width: 70%;
  min-width: 800px;
  outline: 0;
  z-index: 700;
`;

export const Header = styled.div`
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px 20px;
  background-color: ${({ theme }) => theme.colors.mainBackground};
`;

export const HeaderText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.text};
`;

export const IconMotoboy = styled(FaMotorcycle)`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.ultraLarge};
  margin-right: 8px;
`;

export const IconMotoboyInfo = styled(IconMotoboy)`
  font-size: 90px;
  margin: 0 40px 0 20px;
  color: ${({ theme }) => theme.colors.white};
`;

export const IconDate = styled(FaCalendar)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.ultraLarge};
  margin-right: 8px;
`;

export const CloseButton = styled(Button)`
  background-color: transparent;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 40px;
  padding: 0;
`;

export const CloseIcon = styled(IoCloseCircleOutline)`
  font-size: ${({ theme }) => theme.fontSizes.ultraLarge};
  margin: 0 10px 0;
  color: ${({ theme }) => theme.colors.textError};
`;

export const CurrencyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.large};
  margin-right: 5px;
`;

export const Content = styled.div`
  padding: 10px 20px 10px;
  overflow-x: hidden;
  overflow-y: hidden;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  max-height: 80vh;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

export const ClientContainer = styled(Line)`
  padding-top: ${({ theme }) => theme.spacings.large};
  padding-bottom: ${({ theme }) => theme.spacings.large};
`;

export const PersonalInfoContainer = styled.div`
  width: 100%;
  margin: ${({ theme }) => theme.spacings.large};
  padding: ${({ theme }) => theme.spacings.large};

  border: 1px solid ${({ theme }) => theme.colors.mainBackground};
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
  border-radius: 10px;
`;

export const PersonalInfoMessage = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.text};
`;

export const ReceiptInfo = styled(Column)`
  justify-content: space-around;
  flex-direction: row;
`;

export const PaymentInfo = styled(ReceiptInfo)`
  display: flex;
  justify-content: space-space-around;
  align-items: center;
`;

export const Label = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  padding: 10px;
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.white};
`;

export const PaymentLabel = styled(Label)`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  padding: 10px;
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.white};
`;

export const MotoboyInput = styled(Input).attrs({
  placeholder: 'motoboy',
  id: 'motoboyName',
  type: 'text',
  width: '100%',
  margin: '10px 10px 0 0',
})<InputError>`
  text-align: left;
  padding-left: 50px;
  ${({ error }) =>
    error &&
    css`
      border: 1px solid red;
    `}
`;

export const DateInput = styled(Input).attrs({
  placeholder: 'data',
  id: 'date',
  type: 'date',
  margin: '10px 10px 0 0',
  width: '60%',
})<InputError>`
  text-align: left;
  padding-left: 50px;
  ${({ error }) =>
    error &&
    css`
      border: 1px solid red;
    `}
`;

export const DescriptionInput = styled.textarea`
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.textLight};
  min-height: 135px;
  width: 100%;
  padding: 10px;
  resize: none;
`;

export const CostInput = styled(Input).attrs({
  placeholder: 'Valor',
  id: 'cost',
  type: 'text',
  margin: '10px 0 0 15px',
  width: '60%',
})<InputError>`
  text-align: left;
  padding-left: 50px;
  ${({ error }) =>
    error &&
    css`
      border: 1px solid red;
    `}
`;

export const SaveButton = styled(Button)`
  margin: ${({ theme }) => theme.spacings.large};
  width: 200px;
  background-color: ${({ theme }) => theme.colors.secondary};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
`;

export const DeleteButton = styled(Button)`
  margin: ${({ theme }) => theme.spacings.large};
  width: 200px;
  color: ${({ theme }) => theme.colors.textError};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  background-color: ${({ theme }) => theme.colors.cardBackground};
  border: 1px solid ${({ theme }) => theme.colors.textError};
  position: absolute;
  left: 0;
`;

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(23, 25, 28, 0.9);
  z-index: 500;
`;
