import { AppStateContextType } from 'src/types';
import React from 'react';

const AppStateContext = React.createContext<AppStateContextType>(
  {} as AppStateContextType,
);

type ProviderProps = {
  children: React.ReactNode;
};

const AppStateProvider = ({ children }: ProviderProps): JSX.Element => {
  const [menuState, setMenuState] = React.useState<boolean>(false);

  return (
    <AppStateContext.Provider
      value={{
        menuState,
        setMenuState,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

const useAppState = (): AppStateContextType => {
  const context = React.useContext(AppStateContext);

  if (!context) {
    throw new Error('useAppData must be used within an AppStateProvider.');
  }

  return context;
};

export { AppStateProvider, useAppState };
