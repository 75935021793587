import {
  AlertIcon,
  Backdrop,
  Body,
  CloseButton,
  CloseIcon,
  Container,
  Content,
  Header,
  HeaderText,
  Line,
  Message,
  NoButton,
  StyledModal,
  YesButton,
} from './styles';

import React from 'react';
import ReactDOM from 'react-dom';

export type TConfirmationModalProps = {
  show: boolean;
  header: string;
  message: string;
};

interface Props {
  show: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  message: string;
  header: string;
}

const ConfirmationModal: React.FC<Props> = ({
  onConfirm,
  onCancel,
  message,
  show,
  header,
}) => {
  React.useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      const key = event.key || event.keyCode;
      if ((key === 27 || key === 'Escape') && show) {
        onCancel();
      }
    };

    show
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');

    document.addEventListener('keydown', onKeyDown, false);
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [onCancel, show]);

  const modal = (
    <>
      <Backdrop onClick={onCancel} />
      <Container aria-modal tabIndex={-1} role="dialog">
        <StyledModal>
          <Header>
            <HeaderText>
              <AlertIcon />
              {header}
            </HeaderText>
            <CloseButton onClick={onCancel}>
              <CloseIcon />
            </CloseButton>
          </Header>
          <Content>
            <Body>
              <Message>{message}</Message>
              <Line>
                <NoButton onClick={onCancel}>Não</NoButton>
                <YesButton onClick={onConfirm}>Sim</YesButton>
              </Line>
            </Body>
          </Content>
        </StyledModal>
      </Container>
    </>
  );
  return show ? ReactDOM.createPortal(modal, document.body) : null;
};

export default ConfirmationModal;
