import { Container, InvoiceStatusButton, LabelButton } from './styles';
import React from 'react';
import { useTheme } from 'styled-components';
import invoiceStatus from 'src/utils/InvoiceStatus';
import { TInvoiceStatus } from 'src/types';

interface IProps {
  value: TInvoiceStatus;
  onChange(value: TInvoiceStatus): void;
  disabled: boolean;
  disabledButton?: boolean;
  color?: string;
}

const InvoiceStatus: React.FC<IProps> = ({
  value,
  onChange,
  disabled,
  disabledButton,
  color,
}) => {
  const { colors } = useTheme();
  return (
    <Container>
      <InvoiceStatusButton
        color={color || (disabled ? colors.disabled : colors.secondary)}
        onClick={() => onChange(value)}
        disabled={disabledButton}
      >
        {value === invoiceStatus.open && (
          <LabelButton color={color || (disabled ? colors.white : colors.white)}>
            Em <br />
            aberto
          </LabelButton>
        )}
        {value === invoiceStatus.pay && (
          <LabelButton color={color || (disabled ? colors.white : colors.white)}>
            Fatura <br /> paga
          </LabelButton>
        )}
        {value === invoiceStatus.late && (
          <LabelButton color={color || (disabled ? colors.white : colors.white)}>
            Em <br /> atraso
          </LabelButton>
        )}
      </InvoiceStatusButton>
    </Container>
  );
};

export default InvoiceStatus;
