import Button from 'src/components/Button';
import styled from 'styled-components';

type BarDetailProps = {
  color: string;
};

export const Grid = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1.5fr 1fr 1.5fr 1fr;
  grid-template-areas: 'invoices graf graf graf' 'invoices ClientsCard entrega faturamento';
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 10px;
`;

export const Column = styled(Flex)`
  height: 100%;
  max-height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  /* Let's get this party started */
  ::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 0, 0, 0.8);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 0, 0, 0.4);
  }
`;

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.cardBackground};
  flex: 1;
  margin: ${({ theme }) => theme.spacings.medium};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacings.large};
`;

export const ClientsCard = styled(Column)`
  margin: 0;
  grid-area: ClientsCard;
  grid-template-rows: 1fr 1fr;
  min-width: 180px;
`;

export const BoyCard = styled(Card)`
  margin-top: 0;
  margin-left: ${({ theme }) => theme.spacings.medium};
  margin-right: ${({ theme }) => theme.spacings.medium};
  margin-bottom: ${({ theme }) => theme.spacings.large};
`;

export const ClientCard = styled(Card)`
  margin-top: ${({ theme }) => theme.spacings.medium};
  margin-left: ${({ theme }) => theme.spacings.medium};
  margin-right: ${({ theme }) => theme.spacings.medium};
  margin-bottom: ${({ theme }) => theme.spacings.large};
`;

export const InvoicesCard = styled(Card)`
  max-height: 87vh;
  grid-area: invoices;
  grid-row-start: 1;
  grid-row-end: 5;
  margin-left: ${({ theme }) => theme.spacings.large};
  margin-bottom: ${({ theme }) => theme.spacings.large};
  margin-top: ${({ theme }) => theme.spacings.large};
`;

export const ChartCard = styled(Card)`
  grid-area: graf;
  margin-top: ${({ theme }) => theme.spacings.large};
  margin-right: ${({ theme }) => theme.spacings.large};
`;

export const DeliveriesCard = styled(Card)`
  grid-area: entrega;
  margin-bottom: ${({ theme }) => theme.spacings.large};
`;

export const PaymentCard = styled(Card)`
  grid-area: faturamento;
  margin-right: ${({ theme }) => theme.spacings.large};
  margin-bottom: ${({ theme }) => theme.spacings.large};
`;

export const CardTitle = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: ${({ theme }) => theme.fontSizes.cardTitle};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.text};

  white-space: nowrap;
`;

export const CardTitleCenter = styled(CardTitle)`
  justify-content: center;
`;

export const Cipher = styled(CardTitle)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.ultraLarge};
  flex: 0.5;
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: space-around;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.cardBody};
  color: ${({ theme }) => theme.colors.text};
`;

export const CardBodyMultiple = styled(CardBody)`
  font-size: ${({ theme }) => theme.fontSizes.ultraLarge};
`;

export const CardFooter = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.small};
  padding: 0 20px 0;
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  flex: 0.2;
`;

export const BarDetail = styled.div<BarDetailProps>`
  position: absolute;
  width: 10px;
  height: 40%;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  background-color: ${({ color }) => color};
`;

export const SubTitleCard = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-align: center;
`;

export const FieldName = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  text-align: left;
  width: 130px;
`;

export const FieldValue = styled(FieldName)`
  width: 100px;
`;

export const PayButtonIcon = styled(Button)`
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  background-color: ${({ theme }) => theme.colors.textError};
  border-radius: 5px;
  padding: 8px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-right: 5px;
`;

export const FlexStart = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
