import {
  AddCustomerButton,
  Container,
  CustomerInput,
  FilterContainer,
  Flex,
  Grid,
  Label,
} from './styles';
import ConfirmationModal, {
  TConfirmationModalProps,
} from 'src/components/ConfirmationModal';
import CustomerModal, { TCustomerModalProps } from 'src/components/CustomerModal';
import {
  DeleteCustomer,
  FetchCustomers,
  PostCustomer,
  PutCustomer,
} from 'src/services/customers';
import { FaBriefcase, FaPlus } from 'react-icons/fa';
import InformationModal, {
  TInformationModalProps,
} from 'src/components/InformationModal';
import { debounce, isEqual } from 'lodash';

import CustomersTable from 'src/components/CustomersTable';
import { ICustomer } from 'src/types';
import MainContainer from 'src/components/MainContainer';
import React from 'react';
import { useSession } from 'src/hooks/useSession';
import { useTheme } from 'styled-components';

const Customers = (): JSX.Element => {
  const { colors } = useTheme();
  const [customer, setCustomer] = React.useState('');
  const [editedCustomer, setEditedCustomer] = React.useState<ICustomer>({} as ICustomer);
  const [customerList, setCustomerList] = React.useState<ICustomer[]>([]);
  const customerListRef = React.useRef<ICustomer[]>([]);

  const [customerModal, setCustomerModal] = React.useState<TCustomerModalProps>({
    show: false,
    toggleClear: false,
    customer: {} as ICustomer,
  });
  const [infoModal, setInfoModal] = React.useState<TInformationModalProps>({
    show: false,
    message: '',
    headerText: '',
  });

  const [confirmationModal, setConfirmationModal] =
    React.useState<TConfirmationModalProps>({
      show: false,
      message: '',
      header: '',
    });

  const [confirmationForceDeleteModal, setConfirmationFroceDeleteModal] =
    React.useState<TConfirmationModalProps>({
      show: false,
      message: '',
      header: '',
    });

  const [customerDeleteId, setCustomerDeleteId] = React.useState(0);

  const { session, refreshSession } = useSession();
  const { data, isLoading, isFetching } = FetchCustomers(session.token, refreshSession);
  const { isLoading: isPosting, refetch: postCustomer } = PostCustomer(
    session.token,
    refreshSession,
  );
  const { isLoading: isPuting, refetch: putCustomer } = PutCustomer(
    session.token,
    refreshSession,
    editedCustomer.customerId,
  );

  const { isLoading: isDeleting, refetch: deleteCustomer } = DeleteCustomer(
    session.token,
    refreshSession,
  );

  const filterList = debounce((customerName: string) => {
    if (customerName.length <= 0) {
      setCustomerList([...customerListRef.current]);
      return;
    }

    setCustomerList(
      customerListRef.current.filter((localCustomer) =>
        localCustomer.name.toLowerCase().includes(customerName.toLowerCase()),
      ),
    );
  }, 1000);

  const onChangeCustomer = React.useCallback(
    (value: string) => {
      setCustomer(value);
      filterList(value);
    },
    [filterList],
  );

  const onAddCustomer = React.useCallback(
    async (customerToAdd) => {
      setCustomerModal((prev) => ({ ...prev, show: false }));
      try {
        const response = await postCustomer(customerToAdd);
        if (response) {
          setInfoModal({
            headerText: 'Operação Concluída',
            message: response?.data?.msg && 'Cliente inserido com sucesso',
            show: true,
          });
          setCustomerModal((prev) => ({
            toggleClear: !prev.toggleClear,
            show: false,
            customer: {} as ICustomer,
          }));
        }
      } catch (error: any) {
        setInfoModal({
          headerText: 'Erro',
          message:
            error?.response?.data?.msg ?? 'Ocorreu um erro ao processar as informações.',
          show: true,
        });
      }
    },
    [postCustomer],
  );

  const onEditCustomer = React.useCallback(
    async (customerToEdit) => {
      setCustomerModal((prev) => ({ ...prev, show: false }));
      try {
        const response = await putCustomer(customerToEdit);
        if (response) {
          setInfoModal({
            headerText: 'Operação Concluída',
            message: response?.data?.msg && 'Cliente editado com sucesso',
            show: true,
          });
          setCustomerModal((prev) => ({
            toggleClear: !prev.toggleClear,
            show: false,
            customer: {} as ICustomer,
          }));
        }
      } catch (error: any) {
        setInfoModal({
          headerText: 'Erro',
          message:
            error?.response?.data?.msg ?? 'Ocorreu um erro ao processar as informações.',
          show: true,
        });
      }
    },
    [putCustomer],
  );

  const showCustomerModalToEdit = React.useCallback((customerToEdit) => {
    setEditedCustomer(customerToEdit);
    setCustomerModal((prev) => ({
      ...prev,
      show: true,
      customer: customerToEdit,
    }));
  }, []);

  const onDelete = React.useCallback(async () => {
    setConfirmationModal({ show: false, header: '', message: '' });
    try {
      const response = await deleteCustomer({
        customerId: customerDeleteId,
        force: false,
      });
      if (response) {
        setInfoModal({
          headerText: 'Operação Concluída',
          message: 'Cliente excluído com sucesso',
          show: true,
        });
        setCustomerModal({
          show: false,
          customer: {} as ICustomer,
          toggleClear: true,
        });
      }
    } catch (error: any) {
      if (error?.response?.status === 422) {
        setConfirmationFroceDeleteModal({
          show: true,
          header: 'Este cliente possui registros no sistema.',
          message:
            'Esta ação excluirá não só o cliente, mas também todos os registros. Deseja continuar assim mesmo?',
        });
        return;
      }
      setInfoModal({
        headerText: 'Erro',
        message:
          error?.response?.data?.msg ?? 'Ocorreu um erro ao processar as informações.',
        show: true,
      });
    }
  }, [customerDeleteId, deleteCustomer]);

  const onForceDelete = React.useCallback(async () => {
    setConfirmationFroceDeleteModal({ show: false, header: '', message: '' });
    try {
      const response = await deleteCustomer({
        customerId: customerDeleteId,
        force: true,
      });
      if (response) {
        setInfoModal({
          headerText: 'Operação Concluída',
          message: 'Cliente excluído com sucesso',
          show: true,
        });
        setCustomerModal({
          show: false,
          customer: {} as ICustomer,
          toggleClear: true,
        });
      }
    } catch (error: any) {
      setInfoModal({
        headerText: 'Erro',
        message:
          error?.response?.data?.msg ?? 'Ocorreu um erro ao processar as informações.',
        show: true,
      });
    }
  }, [customerDeleteId, deleteCustomer]);

  const showDeleteCustomerConfirmation = (deliverymanId: number) => {
    setCustomerDeleteId(deliverymanId);

    setConfirmationModal({
      header: 'Excluir cliente',
      message: 'Deseja mesmo excluir esse cliente?',
      show: true,
    });
  };

  React.useEffect(() => {
    if (data?.data?.customers) {
      const responseList = data?.data?.customers.concat();

      if (isEqual(responseList, customerListRef.current)) {
        return;
      }
      customerListRef.current = data.data?.customers.concat();

      filterList(customer);
    }
  }, [data, customer, filterList]);

  return (
    <MainContainer>
      <Container>
        <FilterContainer>
          <Grid>
            <Label>Filtros</Label>
            <Flex>
              <CustomerInput
                text={customer}
                icon={<FaBriefcase color={colors.primary} />}
                onChangeValue={onChangeCustomer}
              />
            </Flex>
          </Grid>
          <Grid>
            <AddCustomerButton
              iconLeft={<FaPlus color={colors.white} />}
              disabled={isLoading || !!isFetching || isPosting || isPuting || isDeleting}
              onClick={() =>
                setCustomerModal((prev) => ({
                  ...prev,
                  show: true,
                }))
              }
            >
              NOVO CLIENTE
            </AddCustomerButton>
          </Grid>
        </FilterContainer>
        <CustomersTable
          isLoading={isLoading || !!isFetching || isPosting || isPuting || isDeleting}
          customers={customerList}
          onEditCustomer={showCustomerModalToEdit}
          onDeleteCustomer={showDeleteCustomerConfirmation}
        />
      </Container>
      <CustomerModal
        show={customerModal.show}
        clearCustomer={customerModal.toggleClear}
        onDismiss={() =>
          setCustomerModal((prev) => ({
            ...prev,
            show: false,
            customer: {} as ICustomer,
          }))
        }
        onAdd={onAddCustomer}
        onEdit={onEditCustomer}
        currentCustomer={customerModal.customer}
      />
      <InformationModal
        headerText={infoModal.headerText}
        show={infoModal.show}
        onDismiss={() => setInfoModal({ headerText: '', message: '', show: false })}
        message={infoModal.message}
      />
      <ConfirmationModal
        header={confirmationModal.header}
        message={confirmationModal.message}
        show={confirmationModal.show}
        onCancel={() => setConfirmationModal({ show: false, header: '', message: '' })}
        onConfirm={onDelete}
      />
      <ConfirmationModal
        header={confirmationForceDeleteModal.header}
        message={confirmationForceDeleteModal.message}
        show={confirmationForceDeleteModal.show}
        onCancel={() =>
          setConfirmationFroceDeleteModal({ show: false, header: '', message: '' })
        }
        onConfirm={onForceDelete}
      />
    </MainContainer>
  );
};

export default Customers;
