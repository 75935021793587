import React from 'react';
import { SelectValue } from 'src/types';
import Select, { components } from 'react-select';
import { IconClient, Container } from './styles';

const months: SelectValue[] = [
  { label: 'Janeiro', value: '0' },
  { label: 'Fevereiro', value: '1' },
  { label: 'Março', value: '2' },
  { label: 'Abril', value: '3' },
  { label: 'Maio', value: '4' },
  { label: 'Junho', value: '5' },
  { label: 'Julho', value: '6' },
  { label: 'Agosto', value: '7' },
  { label: 'Setembro', value: '8' },
  { label: 'Outubro', value: '9' },
  { label: 'Novembro', value: '10' },
  { label: 'Dezembro', value: '11' },
];

interface Props {
  defaultMonth?: number;
  label?: string;
  onSelect(month: number): void;
  width?: string;
  margin?: string;
  height?: string;
  align?: string;
  fontSize?: string;
}

const custom = {
  control: (styles: any, state: any) => ({
    ...styles,
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    borderRadius: 10,
    borderColor: state.selectProps.error ? 'red' : 'transparent',
    ':hover': {
      ...styles[':hover'],
      borderColor: state.selectProps.error ? 'red' : 'transparent',
      cursor: 'pointer',
    },
    padding: 5,
    fontSize: 16,
    color: '#333',
    boxShadow: '1px 2px 5px rgba(0, 0, 0, 0.15)',
    alignContent: 'center',
  }),
  option: (styles: any, state: any) => ({
    ...styles,
    backgroundColor: state.isSelected ? '#333' : null,
    color: state.isSelected ? '#fff' : '#070606',
    ':active': {
      ...styles[':active'],
      backgroundColor: '#333',
      color: '#fff',
    },
  }),
  container: (styles: any) => ({
    ...styles,
    height: '100%',
  }),
};

const MonthSelect: React.FC<Props> = ({
  onSelect,
  defaultMonth,
  label,
  width,
  height,
  margin,
  align,
  fontSize,
}) => {
  const date = new Date();
  const defaultMonthValue = months[defaultMonth || date.getMonth()];

  const onChange = React.useCallback(
    (value: number) => {
      onSelect(value);
    },
    [onSelect],
  );

  return (
    <Container width={width} heigth={height} alignContent={align} margin={margin}>
      <Select
        defaultValue={{
          value: defaultMonthValue.label || label || 'Selecione',
          label: defaultMonthValue.label || label || 'Selecione',
        }}
        options={months}
        onChange={(value) => {
          if (value) {
            onChange(parseInt(value.value, 10));
          }
        }}
        styles={custom}
        components={{
          Control: ({ children, ...props }) => (
            <components.Control {...props}>
              <IconClient fontSize={fontSize} /> {children}
            </components.Control>
          ),
        }}
      />
    </Container>
  );
};

export default MonthSelect;
