import useDelete from 'src/hooks/useDelete';
import usePost from 'src/hooks/usePost';
import {
  IPost,
  IPostMotoboyPendencies,
  IPostResponse,
  SessionContextType,
  TDefaultResponse,
} from 'src/types';

export const PostMotoboyPendencies = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
  motoboyId: number,
): IPost<TDefaultResponse<IPostResponse>, IPostMotoboyPendencies> => {
  const { isLoading, isError, error, isSuccess, refetch } = usePost<
    TDefaultResponse<IPostResponse>,
    IPostMotoboyPendencies
  >({
    key: 'post-invoices',
    url: `motoboyPendencies/${motoboyId}`,
    headers: { 'x-access-token': token },
    onFailure: refreshSession,
  });

  return { isLoading, isError, error, isSuccess, refetch };
};

export const DeletePendency = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
  pendencyId: number,
): IPost<TDefaultResponse<null>, null> => {
  const { isLoading, isError, error, isSuccess, refetch } = useDelete<
    TDefaultResponse<null>,
    null
  >({
    key: 'delete-customer',
    url: `/motoboyPendencies/${pendencyId}`,
    headers: { 'x-access-token': token },
    onFailure: refreshSession,
  });

  return { isLoading, isError, error, isSuccess, refetch };
};
