import React from 'react';
import ReactDOM from 'react-dom';
import {
  Backdrop,
  Body,
  CloseButton,
  CloseIcon,
  Container,
  Content,
  Header,
  HeaderText,
  Line,
  StyledModal,
} from './styles';
import { PostMotoboyPendencies } from 'src/services/motoboy-pendencies';
import { useSession } from 'src/hooks/useSession';
import Input from '../Input';
import Button from '../Button';

interface Props {
  motoboyId: number;
  headerText?: string;
  show: boolean;
  onSuccess?: () => void;
  onError?: () => void;
  onDismiss: () => void;
}

const PendencyModal: React.FC<Props> = ({
  motoboyId,
  show,
  headerText = 'Registrar Pendência',
  onSuccess,
  onError,
  onDismiss,
}) => {
  const [description, setDescription] = React.useState('');
  const { session, refreshSession } = useSession();
  const { refetch: createPendency } = PostMotoboyPendencies(
    session.token,
    refreshSession,
    motoboyId,
  );

  React.useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      const key = event.key || event.keyCode;
      if ((key === 27 || key === 'Escape') && show) {
        onDismiss();
      }
    };

    show
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');

    document.addEventListener('keydown', onKeyDown, false);
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [onDismiss, show]);

  const onCreatePendency = React.useCallback(async () => {
    try {
      await createPendency({ description });
      onSuccess?.();
    } catch (error) {
      onError?.();
    }

    setDescription('');
  }, [createPendency, description, onError, onSuccess]);

  const modal = (
    <>
      <Backdrop />
      <Container aria-modal aria-labelledby={headerText} tabIndex={-1} role="dialog">
        <StyledModal>
          <Header>
            <HeaderText>{headerText}</HeaderText>
            <CloseButton onClick={onDismiss}>
              <CloseIcon />
            </CloseButton>
          </Header>
          <Line />
          <Body>
            <Content>
              <Line>
                <Input
                  text={description}
                  value={description}
                  placeholder="Digite a descrição"
                  onChangeValue={(e) => {
                    setDescription(e);
                  }}
                />
              </Line>
              <Line>
                <Button onClick={onCreatePendency}>Registrar Pendência</Button>
              </Line>
            </Content>
          </Body>
        </StyledModal>
      </Container>
    </>
  );

  return show ? ReactDOM.createPortal(modal, document.body) : null;
};

export default PendencyModal;
