import {
  IDeliveryman,
  IDeliverymenResponse,
  IExportResponse,
  IGetDeliveries,
  IMotoboyDeliveriesResponse,
  IPost,
  IPostResponse,
  IPut,
  IPutResponse,
  IResponse,
  SessionContextType,
  TDefaultResponse,
} from 'src/types';

import useFetch from 'src/hooks/useFetch';
import usePost from 'src/hooks/usePost';
import usePut from 'src/hooks/usePut';

interface IPostDeleteDeliveryman {
  motoboyId: number;
  force: boolean;
}

export const FetchMotoboyDeliveries = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
  filters: IGetDeliveries,
): IResponse<IMotoboyDeliveriesResponse> => {
  let url = `/motoboy/report-deliveries/${filters.limit}/${filters.page}/?startdate=${filters.startdate}&finaldate=${filters.finaldate}&paymentTypes=${filters.paymentTypes}`;
  if (filters.client) url = url.concat(`&client=${filters.client}`);
  if (filters.motoboy) url = url.concat(`&motoboy=${filters.motoboy}`);

  const { isLoading, refetch, error, data } = useFetch<IMotoboyDeliveriesResponse>({
    key: 'motoboy-deliveries',
    url,
    headers: { 'x-access-token': token },
    enabled: true,
    onFailure: refreshSession,
  });

  return { isLoading, refetch, error, data };
};

export const ExportMotoboyDeliveries = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
  filters: IGetDeliveries,
): IResponse<IExportResponse> => {
  let url = `/motoboy/export-deliveries/${filters.limit}/${filters.page}/?startdate=${filters.startdate}&finaldate=${filters.finaldate}&paymentTypes=${filters.paymentTypes}`;
  if (filters.client) url = url.concat(`&client=${filters.client}`);
  if (filters.motoboy) url = url.concat(`&motoboy=${filters.motoboy}`);

  const { isLoading, refetch, error, data } = useFetch<IExportResponse>({
    key: 'motoboy-deliveries',
    url,
    headers: { 'x-access-token': token },
    enabled: true,
    onFailure: refreshSession,
  });

  return { isLoading, refetch, error, data };
};

export const FetchDeliverymen = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
): IResponse<IDeliverymenResponse> => {
  const { isLoading, isFetching, error, data, refetch } = useFetch<IDeliverymenResponse>({
    key: 'fetch-motoboy',
    url: '/motoboy',
    headers: { 'x-access-token': token },
    enabled: true,
    onFailure: refreshSession,
  });

  return { isLoading, isFetching, error, data, refetch };
};

export const PostDeliveryman = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
): IPost<TDefaultResponse<IPostResponse>, IDeliveryman> => {
  const { isLoading, isError, error, isSuccess, refetch } = usePost<
    TDefaultResponse<IPostResponse>,
    IDeliveryman
  >({
    key: 'post-motoboy',
    url: '/motoboy',
    headers: { 'x-access-token': token },
    onFailure: refreshSession,
  });

  return { isLoading, isError, error, isSuccess, refetch };
};

export const PutDeliveryman = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
  id: number,
): IPut<TDefaultResponse<IPutResponse>, IDeliveryman> => {
  const { isLoading, isError, error, isSuccess, refetch } = usePut<
    TDefaultResponse<IPutResponse>,
    IDeliveryman
  >({
    key: 'put-motoboy',
    url: `/motoboy/${id}`,
    headers: { 'x-access-token': token },
    onFailure: refreshSession,
  });

  return { isLoading, isError, error, isSuccess, refetch };
};

export const DeleteDeliveryman = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
): IPost<TDefaultResponse<IPostResponse>, IPostDeleteDeliveryman> => {
  const { isLoading, isError, error, isSuccess, refetch } = usePost<
    TDefaultResponse<IPostResponse>,
    IPostDeleteDeliveryman
  >({
    key: 'deletedeliveryman',
    url: `/motoboy/remove`,
    headers: { 'x-access-token': token },
    onFailure: refreshSession,
  });

  return { isLoading, isError, error, isSuccess, refetch };
};
