import {
  Body,
  Container,
  Content,
  ErrorIcon,
  Header,
  HeaderText,
  Line,
  Message,
  StyledModal,
  Backdrop,
} from './styles';

import React from 'react';
import ReactDOM from 'react-dom';
import Loader from 'src/components/Loader';

export type TInformationModalProps = {
  show: boolean;
  headerText: string;
  message: string;
};

type TProps = {
  show: boolean;
  headerText: string;
  message: string;
};

const LoaderModal: React.FC<TProps> = ({ show, headerText, message }) => {
  React.useEffect(() => {
    show
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
  }, [show]);

  const modal = (
    <>
      <Backdrop />
      <Container aria-modal aria-labelledby={headerText} tabIndex={-1} role="dialog">
        <StyledModal>
          <Header>
            <ErrorIcon />
            <HeaderText>{headerText}</HeaderText>
          </Header>
          <Content>
            <Body>
              <Message>{message}</Message>
              <Line>
                <Loader />
              </Line>
            </Body>
          </Content>
        </StyledModal>
      </Container>
    </>
  );
  return show ? ReactDOM.createPortal(modal, document.body) : null;
};

export default LoaderModal;
