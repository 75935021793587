import App from 'src/App';
import AppProvider from 'src/providers/AppProvider';
import React from 'react';
import ReactDOM from 'react-dom';

ReactDOM.render(
  <AppProvider>
    <App />
  </AppProvider>,
  document.getElementById('root'),
);
