import {
  Backdrop,
  Body,
  Container,
  Content,
  DismissButton,
  ErrorIcon,
  Header,
  HeaderText,
  Line,
  Message,
  StyledModal,
} from './styles';

import React from 'react';
import ReactDOM from 'react-dom';

export type TInformationModalProps = {
  show: boolean;
  headerText: string;
  message: string;
};

type TProps = {
  show: boolean;
  headerText: string;
  message: string | JSX.Element;
  onDismiss(): void;
};

const InformationModal: React.FC<TProps> = ({ show, headerText, message, onDismiss }) => {
  React.useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      const key = event.key || event.keyCode;
      if ((key === 27 || key === 'Escape') && show) {
        onDismiss();
      }
    };

    show
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');

    document.addEventListener('keydown', onKeyDown, false);
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [onDismiss, show]);

  const modal = (
    <>
      <Backdrop onClick={onDismiss} />
      <Container aria-modal aria-labelledby={headerText} tabIndex={-1} role="dialog">
        <StyledModal>
          <Header>
            <ErrorIcon />
            <HeaderText>{headerText}</HeaderText>
          </Header>
          <Content>
            <Body>
              <Message>{message}</Message>
              <Line>
                <DismissButton onClick={onDismiss}>OK</DismissButton>
              </Line>
            </Body>
          </Content>
        </StyledModal>
      </Container>
    </>
  );
  return show ? ReactDOM.createPortal(modal, document.body) : null;
};

export default InformationModal;
