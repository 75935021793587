import {
  AccessContainer,
  AddIcon,
  AddressInput,
  Backdrop,
  Body,
  CloseButton,
  CloseIcon,
  Column,
  Container,
  Content,
  CpfInput,
  EmailIcon,
  EmailInput,
  Header,
  HeaderText,
  Label,
  Line,
  LockIcon,
  ModalContainer,
  NameInput,
  PasswordInput,
  PersonalInfoContainer,
  PersonalInfoMessage,
  PhoneIcon,
  PhoneInput,
  PrimaryInfoContainer,
  SaveButton,
  StyledModal,
  UserIcon,
  LabelError,
  ColumnField,
} from './styles';
import { maskToCpf, maskToPhone } from 'src/utils/InputMask';

import { IDeliveryman } from 'src/types';
import React from 'react';
import ReactDOM from 'react-dom';
import YesNoSelector from 'src/components/YesNoSelector';
import { isEmpty } from 'lodash';

export type TDeliverymanModalProps = {
  show: boolean;
  toggleClear: boolean;
  deliveryman: IDeliveryman;
};

type TProps = {
  show: boolean;
  clearDeliveryman: boolean;
  currentDeliveryman: IDeliveryman;
  onDismiss(): void;
  onAdd(deliverymanToAdd: Partial<IDeliveryman>): void;
  onEdit(deliverymanToEdit: Partial<IDeliveryman>): void;
};

type TFormErrors = {
  [Property in keyof IDeliveryman]: boolean;
};

const DeliverymanModal: React.FC<TProps> = ({
  show = false,
  clearDeliveryman,
  currentDeliveryman,
  onDismiss,
  onAdd,
  onEdit,
}) => {
  const [deliveryman, setDeliveryman] = React.useState<IDeliveryman>({} as IDeliveryman);

  const [errors, setErrors] = React.useState<TFormErrors>({} as TFormErrors);
  const [header, setHeader] = React.useState<'NOVO MOTOQUEIRO' | 'EDITAR MOTOQUEIRO'>(
    'NOVO MOTOQUEIRO',
  );

  const onChange = React.useCallback((key: keyof IDeliveryman, value: any) => {
    setDeliveryman((prev) => ({ ...prev, [key]: value }));
    setErrors((prev) => ({ ...prev, [key]: false }));
  }, []);

  const onSave = () => {
    const submitErrors = {
      name: true,
      cpf: true,
      address: true,
      phone: true,
      email: true,
      password: true,
    } as TFormErrors;
    let hasError = false;
    Object.entries(deliveryman).forEach(([key, value]) => {
      submitErrors[key as keyof IDeliveryman] = !value || value === '';
    });

    submitErrors.enable = false;
    submitErrors.motoboyId = false;
    submitErrors.ricardexId = false;
    submitErrors.password =
      header === 'NOVO MOTOQUEIRO' &&
      (deliveryman.password === undefined || deliveryman.password === '');

    Object.entries(submitErrors).forEach(([, value]) => {
      hasError = hasError || !!value;
    });

    if (hasError) {
      setErrors({ ...submitErrors });
      return;
    }

    if (header === 'NOVO MOTOQUEIRO') {
      onAdd({
        ...deliveryman,
        cpf: deliveryman.cpf.replace(/\D/g, ''),
        enable: deliveryman.enable ? deliveryman.enable : false,
      });
    } else {
      onEdit({ ...deliveryman, cpf: deliveryman.cpf.replace(/\D/g, '') });
    }
  };

  React.useEffect(() => {
    if (!isEmpty(currentDeliveryman)) {
      setHeader('EDITAR MOTOQUEIRO');
      setDeliveryman({ ...currentDeliveryman });
      setErrors({} as TFormErrors);
    } else {
      setHeader('NOVO MOTOQUEIRO');
      setDeliveryman({} as IDeliveryman);
      setErrors({} as TFormErrors);
    }
  }, [currentDeliveryman]);

  React.useEffect(() => {
    setDeliveryman({} as IDeliveryman);
    setErrors({} as TFormErrors);
  }, [clearDeliveryman]);

  React.useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      const key = event.key || event.keyCode;
      if ((key === 27 || key === 'Escape') && show) {
        onDismiss();
      }
    };

    show
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');

    document.addEventListener('keydown', onKeyDown, false);
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [onDismiss, show]);

  const modal = (
    <Container>
      <Backdrop onClick={onDismiss} />
      <ModalContainer aria-modal tabIndex={-1} role="dialog">
        <StyledModal>
          <Header>
            <HeaderText>
              <AddIcon />
              {header}
            </HeaderText>
            <CloseButton onClick={onDismiss}>
              <CloseIcon />
            </CloseButton>
          </Header>
          <Content>
            <Body>
              <PrimaryInfoContainer>
                <UserIcon />
                <Column>
                  <NameInput
                    error={!!errors.name}
                    text={deliveryman.name}
                    onChangeValue={(value) => onChange('name', value)}
                  />
                  <LabelError>{!!errors.name && 'Nome é obrigatório'}</LabelError>
                  <Line>
                    <ColumnField>
                      <EmailInput
                        error={!!errors.email}
                        icon={<EmailIcon />}
                        text={deliveryman.email}
                        onChangeValue={(value) => onChange('email', value)}
                      />
                      <LabelError>{!!errors.email && 'Email é obrigatório'}</LabelError>
                    </ColumnField>
                    <ColumnField>
                      <PhoneInput
                        error={!!errors.phone}
                        icon={<PhoneIcon />}
                        text={deliveryman.phone}
                        onChangeValue={(value) => onChange('phone', maskToPhone(value))}
                      />
                      <LabelError>
                        {!!errors.phone && 'Telefone é obrigatório'}
                      </LabelError>
                    </ColumnField>
                  </Line>
                </Column>
              </PrimaryInfoContainer>
              <PersonalInfoContainer>
                <PersonalInfoMessage>INFORMAÇÕES ADICIONAIS</PersonalInfoMessage>
                <ColumnField>
                  <AddressInput
                    error={!!errors.address}
                    text={deliveryman.address}
                    onChangeValue={(value) => onChange('address', value)}
                  />
                  <LabelError>{!!errors.address && 'Endereço é obrigatório'}</LabelError>
                </ColumnField>
                <ColumnField>
                  <CpfInput
                    error={!!errors.cpf}
                    text={maskToCpf(deliveryman.cpf)}
                    onChangeValue={(value) => onChange('cpf', maskToCpf(value))}
                  />
                  <LabelError>{!!errors.cpf && 'CPF é obrigatório'}</LabelError>
                </ColumnField>
                <ColumnField>
                  <PasswordInput
                    icon={<LockIcon />}
                    error={!!errors.password}
                    text={deliveryman.password ?? ''}
                    onChangeValue={(value) => onChange('password', value)}
                  />
                  <LabelError>{!!errors.password && 'Senha é obrigatório'}</LabelError>
                </ColumnField>
                <AccessContainer>
                  <Label>Acesso liberado:</Label>
                  <YesNoSelector
                    value={!!deliveryman.enable}
                    onChangeValue={(value) => onChange('enable', value)}
                  />
                </AccessContainer>
              </PersonalInfoContainer>
              <SaveButton onClick={onSave}>SALVAR</SaveButton>
            </Body>
          </Content>
        </StyledModal>
      </ModalContainer>
    </Container>
  );
  return show ? ReactDOM.createPortal(modal, document.body) : null;
};

export default DeliverymanModal;
