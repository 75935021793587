import { BilledIcon, CashIcon, PixIcon } from 'src/components/Icon';
import { Container, PaymentButton } from './styles';
import React from 'react';
import { TPayment } from 'src/types';
import { useTheme } from 'styled-components';

interface IProps {
  value: TPayment;
  onChange(value: TPayment): void;
  disabled: boolean;
  disabledButton?: boolean;
  color?: string;
}

const PaymentType: React.FC<IProps> = ({
  value,
  onChange,
  disabled,
  disabledButton,
  color,
}) => {
  const { colors } = useTheme();
  return (
    <Container>
      <PaymentButton
        color={color || (disabled ? colors.disabled : colors.secondary)}
        onClick={() => onChange(value)}
        disabled={disabledButton}
      >
        {value === 'pix' && (
          <PixIcon color={color || (disabled ? colors.disabled : colors.secondary)} />
        )}
        {value === 'billed' && (
          <BilledIcon color={color || (disabled ? colors.disabled : colors.secondary)} />
        )}
        {value === 'money' && (
          <CashIcon color={color || (disabled ? colors.disabled : colors.secondary)} />
        )}
      </PaymentButton>
    </Container>
  );
};

export default PaymentType;
