import Button from 'src/components/Button';
import Input from 'src/components/Input';
import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  padding: 10px;
`;

export const Flex = styled.div`
  display: flex;
`;

export const Container = styled.div`
  width: calc(100vw - 60px);
  height: calc(100vh - 70px);
`;

export const FilterContainer = styled.div`
  flex-wrap: wrap;
  width: calc(100% - 20px);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 10px;
  margin: 10px;
  background-color: ${({ theme }) => theme.colors.cardBackground};
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.text};
`;

export const CustomerInput = styled(Input).attrs({
  placeholder: 'buscar clientes',
  id: 'customer',
  type: 'text',
  autocomplete: 'on',
  margin: '30px 18px 0 0',
  width: '250px',
})`
  font-size: 16px;
`;

export const AddCustomerButton = styled(Button)`
  width: 280px;
  margin: 40px 0 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
`;
