import { IDashboardResponse, IResponse, SessionContextType } from 'src/types';

import useFetch from 'src/hooks/useFetch';

export const FetchDashboard = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
): IResponse<IDashboardResponse> => {
  const { isLoading, isFetching, error, data, refetch } = useFetch<IDashboardResponse>({
    key: 'fetch-dashboard',
    url: '/owner/getDashboard',
    headers: { 'x-access-token': token },
    enabled: true,
    onFailure: refreshSession,
  });

  return { isLoading, isFetching, error, data, refetch };
};
