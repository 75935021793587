import { ButtonObj, IconLeft, IconRight } from './styles';

import React from 'react';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  iconRight?: React.ReactNode;
  iconLeft?: React.ReactNode;
}

const Button: React.FC<Props> = ({ children, iconRight, iconLeft, ...rest }) => {
  return (
    <ButtonObj {...rest}>
      <>
        {iconRight && <IconRight>{iconRight}</IconRight>}
        {children}
        {iconLeft && <IconLeft>{iconLeft}</IconLeft>}
      </>
    </ButtonObj>
  );
};

export default Button;
