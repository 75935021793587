import { ResponsiveBar } from '@nivo/bar';
import styled from 'styled-components';

const BarComponent: any = ResponsiveBar;

export const Graph = styled(BarComponent).attrs(({ theme }) => ({
  colors: [theme.colors.secondary, theme.colors.primaryAlpha],
  theme: theme.barTheme,
  keys: ['total', 'value'],
  indexBy: 'month',
  padding: 0.4,
  valueScale: { type: 'symlog' },
  margin: { top: 40, bottom: 40, left: 30, right: 30 },
  axisTop: null,
  axisRight: null,
  axisLeft: null,
  axisBottom: {
    tickSize: 0,
    tickPadding: 20,
    tickRotation: 0,
    legendPosition: 'middle',
    legendOffset: 32,
  },
  labelTextColor: theme.colors.text,
  enableGridY: false,
  isInteractive: false,
  animate: true,
  motionStiffness: 90,
  motionDamping: 15,
}))``;

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const GraphContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
`;

export const Footer = styled.span`
  flex: 0.2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.small};
  padding: 0 60px 0;
  color: ${({ theme }) => theme.colors.text};

  overflow: hidden;
  white-space: nowrap;
`;

export const Title = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.cardTitle};
  color: ${({ theme }) => theme.colors.text};
  overflow: hidden;
  white-space: nowrap;
`;
