import Button from 'src/components/Button';
import Input from 'src/components/Input';
import { Link } from 'react-router-dom';
import backgroundImage from 'src/assets/images/background.png';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;

  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 30px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0 30px;
  width: 100%;
  height: 60px;
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 30px;
`;

export const Description = styled.div`
  padding: 10px 20px 0;
  font-style: normal;
  color: ${({ theme }) => theme.colors.white};
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.62rem;
`;

export const Status = styled.div`
  padding: 10px 20px 0;
  font-style: normal;

  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.secondary};
`;

export const AuthForm = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  background: ${(props) => props.theme.colors.background};
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 50px 50px 0;
`;

export const EmailInput = styled(Input).attrs({
  placeholder: 'email',
  id: 'email',
  type: 'text',
})``;

export const BackButton = styled(Link)`
  text-decoration: none;

  width: 400px;
  padding: ${({ theme }) => theme.spacings.medium};
  text-align: center;
  background: transparent;
  color: ${(props) => props.theme.colors.primary};
  border: 1px solid ${(props) => props.theme.colors.primary};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 49px;
  margin: 40px 0 0;

  font-style: normal;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.primary};

  transition-duration: 0.2s;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

export const SendEmailButton = styled(Button)`
  width: 400px;
  margin: 40px 0 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
`;
