import { IPost, SessionContextType, THeaders } from 'src/types';
import { useMutation, useQueryClient } from 'react-query';

import api from 'src/services/api';

interface ICommonPost {
  key: string;
  headers?: THeaders;
  onFailure?: SessionContextType['refreshSession'];
  retryCount?: number;
}

type TPost<T, F> = ICommonPost &
  (
    | {
        url: string;
        customMutationFn?: never;
        onHeadersChanged?: never;
      }
    | {
        url?: never;
        customMutationFn(data: F): Promise<T>;
        onHeadersChanged(headers: THeaders): void;
      }
  );

export function postMutationFn<T, F>(
  url: string,
  data: F,
  headers?: THeaders,
): Promise<T> {
  return api.post(url, data, { headers });
}

export default function usePost<T, F>({
  key,
  url,
  headers: headersParam,
  onFailure,
  customMutationFn,
  onHeadersChanged,
  retryCount = 3,
}: TPost<T, F>): IPost<T, F> {
  const queryClient = useQueryClient();
  let headers = headersParam;
  const {
    isLoading,
    isError,
    error,
    isSuccess,
    mutateAsync: refetch,
  } = useMutation<T, Error, F>(
    key,
    customMutationFn ?? ((data) => postMutationFn(url, data, headers)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
      retry: (failureCount, { message }) => {
        if (message?.includes('401')) {
          if (failureCount === 0) {
            onFailure?.()?.then((token) => {
              if (token) {
                headers = {
                  ...headersParam,
                  'x-access-token': token,
                };
                onHeadersChanged?.(headers);

                queryClient.invalidateQueries(key);
              }
            });
          }

          return failureCount < retryCount;
        }

        return false;
      },
    },
  );

  return { isLoading, isError, error, isSuccess, refetch };
}
