import React from 'react';
import { SelectValue } from 'src/types';
import Select, { components } from 'react-select';
import { IconClient, Container } from './styles';

interface Props {
  defaultYear?: number;
  onSelect(year: number): void;
  min: number;
  max: number;
  width?: string;
  margin?: string;
  height?: string;
  align?: string;
  fontSize?: string;
}

const custom = {
  control: (styles: any, state: any) => ({
    ...styles,
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    borderRadius: 10,
    borderColor: state.selectProps.error ? 'red' : 'transparent',
    ':hover': {
      ...styles[':hover'],
      borderColor: state.selectProps.error ? 'red' : 'transparent',
      cursor: 'pointer',
    },
    padding: 5,
    fontSize: 16,
    color: '#333',
    boxShadow: '1px 2px 5px rgba(0, 0, 0, 0.15)',
    alignContent: 'center',
  }),
  option: (styles: any, state: any) => ({
    ...styles,
    backgroundColor: state.isSelected ? '#333' : null,
    color: state.isSelected ? '#fff' : '#070606',
    ':active': {
      ...styles[':active'],
      backgroundColor: '#333',
      color: '#fff',
    },
  }),
  container: (styles: any) => ({
    ...styles,
    height: '100%',
  }),
};

const YearSelect: React.FC<Props> = ({
  onSelect,
  defaultYear,
  width,
  height,
  margin,
  align,
  fontSize,
  min,
  max,
}) => {
  const [years, setYears] = React.useState<SelectValue[]>([]);

  const date = new Date();
  React.useEffect(() => {
    const diff = max - min;
    const yearsMap: SelectValue[] = [];

    for (let i = 0; i <= diff; i += 1) {
      yearsMap.push({
        value: (min + i).toString(),
        label: (min + i).toString(),
      });
    }

    setYears(yearsMap);
  }, [max, min]);

  const onChange = React.useCallback(
    (value: number) => {
      onSelect(value);
    },
    [onSelect],
  );

  return (
    <Container width={width} heigth={height} alignContent={align} margin={margin}>
      {years.length > 0 && (
        <Select
          defaultValue={{
            value: defaultYear ? defaultYear.toString() : date.getFullYear().toString(),
            label: defaultYear ? defaultYear.toString() : date.getFullYear().toString(),
          }}
          options={years}
          onChange={(value) => {
            if (value) {
              onChange(parseInt(value.value, 10));
            }
          }}
          styles={custom}
          components={{
            Control: ({ children, ...props }) => (
              <components.Control {...props}>
                <IconClient fontSize={fontSize} /> {children}
              </components.Control>
            ),
          }}
        />
      )}
    </Container>
  );
};

export default YearSelect;
