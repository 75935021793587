import Button from 'src/components/Button';
import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.theme.colors.background};
  padding: 0 20px 0;
  justify-content: space-between;
  z-index: 200;
`;

export const UserName = styled.div`
  white-space: nowrap;
  padding: 0 30px 0 10px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 60px;
`;

export const Logout = styled(Button)`
  padding-right: 30px;
  width: 120px;
  text-align: center;
  background: transparent;
  color: ${(props) => props.theme.colors.textError};
`;

export const Line = styled.div`
  width: 0px;
  height: 30px;
  border: 1px solid ${({ theme }) => theme.colors.white};
`;
