import React from 'react';
import InvoicesTable from 'src/components/InvoicesTable';
import MainContainer from 'src/components/MainContainer';
import InvoiceStatus from 'src/components/InvoiceStatus';
import { useSession } from 'src/hooks/useSession';
import { FetchInvoices, PostInvoice } from 'src/services/invoices';
import { IExportResponse, IGetInvoices, TInvoiceStatus } from 'src/types';
import {
  ButtonCard,
  Container,
  Customer,
  ExportButton,
  ExportCard,
  FilterContainer,
  Flex,
  Grid,
  Label,
} from './styles';
import { FaFilePdf } from 'react-icons/fa';
import { FetchCustomers } from 'src/services/customers';
import { useTheme } from 'styled-components';
import InformationModal, {
  TInformationModalProps,
} from 'src/components/InformationModal';
import ConfirmationModal from 'src/components/ConfirmationModal';
import MonthSelect from 'src/components/MonthSelect';
import YearSelect from 'src/components/YearSelect';
import { api_service } from 'src/services/api-service';
import axios, { AxiosError } from 'axios';
import { FetchReportStatus } from 'src/services/deliveries';

const Invoices = (): JSX.Element => {
  const { colors } = useTheme();
  const date = new Date();
  const [filters, setFilters] = React.useState<IGetInvoices>({
    invoiceStatus: ['open', 'pay', 'late'],
    client: undefined,
    motoboy: undefined,
    month: date.getMonth(),
    year: date.getFullYear(),
  });

  const [informationModal, setInformationModal] = React.useState({
    show: false,
    headerText: '',
    message: '',
  });

  const [confirmationInvoice, setConfirmationInvoice] = React.useState({
    show: false,
    header: '',
    message: '',
    onCancel: () => {
      setConfirmationInvoice((prev) => ({
        ...prev,
        show: false,
        header: '',
        message: '',
      }));
    },
    customerId: -1,
  });

  const [loaderModal, setLoaderModal] = React.useState<TInformationModalProps>({
    show: false,
    message: '',
    headerText: '',
  });

  const [key, setKey] = React.useState('');

  const [filterHeight, setFilterHeight] = React.useState<number>();

  const { session, refreshSession } = useSession();
  const { data, isLoading, refetch } = FetchInvoices(
    session.token,
    refreshSession,
    filters,
  );

  const {
    isLoading: loadingPost,
    refetch: postInvoice,
    isError: isErrorPost,
    isSuccess: isSuccessPost,
  } = PostInvoice(session.token, refreshSession);

  const { refetch: fileStatus } = FetchReportStatus(session.token, refreshSession, key);

  const { data: customerList } = FetchCustomers(session.token, refreshSession);

  const onChangeClient = (value: Array<number> | undefined) => {
    setFilters({ ...filters, client: value });
  };

  const onChangeInvoiceStatus = (value: TInvoiceStatus) => {
    const index = filters.invoiceStatus.findIndex((invoice) => invoice === value);
    if (index && index < 0) {
      setFilters({ ...filters, invoiceStatus: [...filters.invoiceStatus, value] });
    } else
      setFilters({
        ...filters,
        invoiceStatus: filters.invoiceStatus.filter((invoice) => invoice !== value),
      });
  };

  const generateInvoice = async () => {
    await postInvoice({
      client: confirmationInvoice.customerId,
      month: filters.month,
      year: filters.year,
    });

    if (!loadingPost && !isErrorPost) {
      confirmationInvoice.onCancel();
      setInformationModal({
        show: true,
        headerText: 'Sucesso',
        message: 'Pagamento de fatura realizado com sucesso.',
      });
    }
  };

  const showFile = React.useCallback(
    async (url: string, keyValue: string, count: number) => {
      setKey(keyValue);
      if (keyValue.length > 0) {
        const response = await fileStatus?.();
        const file = response?.data?.data?.file;

        if (file && file.status === 'success') {
          setLoaderModal({
            headerText: '',
            message: '',
            show: false,
          });
          setInformationModal({
            headerText: 'Sucesso',
            message: 'O Relatório foi gerado com sucesso',
            show: true,
          });
          window.open(url);
        } else if (file && file.status === 'error') {
          setInformationModal({
            headerText: 'Erro!',
            message: 'Ocorreu um erro ao gerar o relatório.',
            show: true,
          });
        } else {
          setTimeout(() => {
            showFile(url, keyValue, count - 1);
          }, 3000);
        }
      } else if (count > 0) {
        setTimeout(() => {
          showFile(url, keyValue, count - 1);
        }, 3000);
      } else {
        setInformationModal({
          headerText: 'Erro!',
          message: 'Ocorreu um erro ao gerar o relatório.',
          show: true,
        });
      }
    },
    [fileStatus],
  );

  const onConfirmGenerateInvoice = (customerId: number) => {
    setConfirmationInvoice((prev) => ({
      ...prev,
      show: true,
      customerId,
      header: 'Atenção!',
      message: 'Confirmar pagamento da fatura?',
    }));
  };

  const exportInvoices = () => {
    if (data?.data?.data.length === 0) {
      setInformationModal({
        headerText: 'Aviso!',
        message: 'Não há dados a serem exportados',
        show: true,
      });

      return;
    }

    let url = '/generate-financial-summary/?';

    Object.keys(filters).forEach((value, index) => {
      url = url.concat(`${value}=${Object.values(filters)[index]}&`);
    });

    api_service
      .get<IExportResponse>(url, {
        headers: { ...axios.defaults.headers, 'x-access-token': session.token },
      })
      .then((response) => {
        if (response.data.url) {
          showFile(response.data.url, response.data.key, 9);
        }
      })
      .catch((err: Error | AxiosError) => {
        setLoaderModal({
          headerText: '',
          message: '',
          show: false,
        });
        let message = '';
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 500 || err.response?.status === 502) {
            message = 'Erro interno no servidor.';
          } else if (err.response?.status === 400) {
            message = 'Ocorreu um erro ao processar as informações.';
          }
        } else {
          message = 'Erro desconhecido.';
        }
        setInformationModal({
          headerText: 'Erro',
          message,
          show: true,
        });
      });
  };

  React.useEffect(() => {
    const height = document.getElementById('filters')?.clientHeight;

    setFilterHeight(height);

    refetch?.();
  }, [refetch, filters]);

  return (
    <MainContainer>
      <InformationModal
        show={informationModal.show}
        headerText={informationModal.headerText}
        message={informationModal.message}
        onDismiss={() => {
          setInformationModal({ show: false, headerText: '', message: '' });
        }}
      />
      <ConfirmationModal
        show={confirmationInvoice.show}
        header={confirmationInvoice.header}
        message={confirmationInvoice.message}
        onCancel={confirmationInvoice.onCancel}
        onConfirm={generateInvoice}
      />
      <Container>
        <FilterContainer id="filters">
          <Grid>
            <Label>Filtros</Label>
            <Flex>
              <Customer
                onSelect={(customers) => {
                  if (customers && customers.length > 0) {
                    onChangeClient(customers.map((e) => e.customerId));
                  } else {
                    onChangeClient(undefined);
                  }
                }}
                customerList={customerList?.data?.customers || []}
              />
              <MonthSelect
                onSelect={(month) => {
                  setFilters((prev) => ({ ...prev, month }));
                }}
                height="48px"
                width="250px"
                defaultMonth={filters.month}
                margin="30px 18px 0 0"
              />
              <YearSelect
                min={2021}
                max={new Date().getFullYear()}
                onSelect={(year) => {
                  setFilters((prev) => ({ ...prev, year }));
                }}
                height="48px"
                width="250px"
                margin="30px 18px 0 0"
              />
            </Flex>
          </Grid>
          <Grid>
            <Label>Tipo de pagamento</Label>
            <Flex>
              <ButtonCard>
                <InvoiceStatus
                  value="pay"
                  onChange={onChangeInvoiceStatus}
                  disabled={!filters.invoiceStatus.includes('pay')}
                />
              </ButtonCard>
              <ButtonCard>
                <InvoiceStatus
                  value="open"
                  onChange={onChangeInvoiceStatus}
                  disabled={!filters.invoiceStatus.includes('open')}
                />
              </ButtonCard>
              <ButtonCard>
                <InvoiceStatus
                  value="late"
                  onChange={onChangeInvoiceStatus}
                  disabled={!filters.invoiceStatus.includes('late')}
                />
              </ButtonCard>
            </Flex>
          </Grid>
          <Grid>
            <Label>Exportar</Label>
            <ExportCard>
              <ExportButton onClick={exportInvoices}>
                <FaFilePdf color={colors.text} />
                <Label>PDF</Label>
              </ExportButton>
            </ExportCard>
          </Grid>
        </FilterContainer>
        <InvoicesTable
          filtersHeight={filterHeight ?? 0}
          isLoading={isLoading}
          invoices={data?.data?.data ?? []}
          generateInvoice={onConfirmGenerateInvoice}
        />
      </Container>
    </MainContainer>
  );
};

export default Invoices;
