export const dateFormat = (date: Date): string => {
  const d = new Date(date);
  const da = new Intl.DateTimeFormat('pt-br').format(d);
  return `${da}`;
};

export const timeFormat = (date: Date): string => {
  const d = new Date(date);
  const time = new Intl.DateTimeFormat('br', {
    timeStyle: 'short',
    hour12: false,
  }).format(d);
  return `${time}`;
};

export const dateTimeFormat = (date: Date): string => {
  const d = new Date(date);
  const da = new Intl.DateTimeFormat('pt-br').format(d);
  const time = new Intl.DateTimeFormat('br', {
    timeStyle: 'short',
    hour12: false,
  }).format(d);
  return `${da} ${time}`;
};

export const dateInputFormat = (date: Date): string => {
  const year = date.getFullYear();
  const month =
    date.getMonth() + 1 > 9
      ? (date.getMonth() + 1).toString()
      : '0'.concat((date.getMonth() + 1).toString());
  const day =
    date.getDate() > 9
      ? date.getDate().toString()
      : '0'.concat(date.getDate().toString());

  return `${year}-${month}-${day}`;
};

export const dateFormatBR = (date: string): string => {
  const parts = date.split('-');

  const dt = new Date(
    parseInt(parts[0], 10),
    parseInt(parts[1], 10) - 1,
    parseInt(parts[2], 10),
  ).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return dt;
};

export const monthFormat = (referenceMonth: number): string => {
  return referenceMonth + 1 <= 9 ? `0${referenceMonth + 1}` : `${referenceMonth + 1}`;
};
