import { Container } from './styles';
import React from 'react';

interface Props {
  open: boolean;
  setOpen(state: boolean): void;
}

const Burger: React.FC<Props> = ({ open, setOpen }) => {
  return (
    <Container open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </Container>
  );
};

export default Burger;
