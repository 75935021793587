import useFetch from 'src/hooks/useFetch';
import usePost from 'src/hooks/usePost';
import usePut from 'src/hooks/usePut';
import {
  IGetInvoices,
  IInvoicesResponse,
  IPost,
  IPostInvoices,
  IPostResponse,
  IPut,
  IPutResponse,
  IResponse,
  SessionContextType,
  TDefaultResponse,
} from 'src/types';

export const FetchInvoices = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
  filters: IGetInvoices,
): IResponse<IInvoicesResponse> => {
  let url = `/invoice/invoiceMonth/?year=${filters.year}&month=${filters.month}&invoiceStatus=${filters.invoiceStatus}`;
  if (filters.client) url = url.concat(`&client=${filters.client}`);
  if (filters.motoboy) url = url.concat(`&motoboy=${filters.motoboy}`);

  const { isLoading, refetch, error, data } = useFetch<IInvoicesResponse>({
    key: 'deliveries',
    url,
    headers: { 'x-access-token': token },
    enabled: true,
    onFailure: refreshSession,
  });

  return { isLoading, refetch, error, data };
};

export const PostInvoice = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
): IPost<TDefaultResponse<IPostResponse>, IPostInvoices> => {
  const { isLoading, isError, error, isSuccess, refetch } = usePost<
    TDefaultResponse<IPostResponse>,
    IPostInvoices
  >({
    key: 'post-invoices',
    url: 'invoice/',
    headers: { 'x-access-token': token },
    onFailure: refreshSession,
  });

  return { isLoading, isError, error, isSuccess, refetch };
};

export const PayInvoice = (
  token: string,
  refreshSession: SessionContextType['refreshSession'],
): IPut<TDefaultResponse<IPutResponse>, IPostInvoices> => {
  const { isLoading, isError, error, isSuccess, refetch } = usePut<
    TDefaultResponse<IPutResponse>,
    IPostInvoices
  >({
    key: 'pay-invoice',
    url: 'invoice/pay',
    headers: { 'x-access-token': token },
    onFailure: refreshSession,
  });

  return { isLoading, isError, error, isSuccess, refetch };
};
