import { BrowserRouter } from 'react-router-dom';
import Customer from 'src/pages/Customer';
import Dashboard from 'src/pages/Dashboard';
import Deliveries from 'src/pages/Deliveries';
import Deliverymen from 'src/pages/Deliverymen';
import ForgotPassword from 'src/pages/ForgotPassword';
import Invoices from 'src/pages/Invoices';
import Login from 'src/pages/Login';
import MotoboyDeliveries from 'src/pages/MotoboyDeliveries';
import MyData from 'src/pages/MyData';
import ProtectedRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

function Routes(): JSX.Element {
  return (
    <BrowserRouter basename="/">
      <ProtectedRoute path="/" exact component={Dashboard} />
      <ProtectedRoute path="/MyData" exact component={MyData} />
      <ProtectedRoute path="/Deliveries" exact component={Deliveries} />
      <ProtectedRoute path="/Deliverymen" exact component={Deliverymen} />
      <ProtectedRoute path="/Customer" exact component={Customer} />
      <ProtectedRoute path="/MotoboyDeliveries" exact component={MotoboyDeliveries} />
      <ProtectedRoute path="/Invoices" exact component={Invoices} />
      <PublicRoute path="/login" exact component={Login} />
      <PublicRoute path="/forgotPassword" exact component={ForgotPassword} />
    </BrowserRouter>
  );
}

export default Routes;
