import styled from 'styled-components';
import { IoBriefcaseSharp } from 'react-icons/io5';

interface ContainerProps {
  width?: string;
  margin?: string;
  alignContent?: string;
  heigth?: string;
}

interface IconProps {
  fontSize?: string;
}

export const IconClient = styled(IoBriefcaseSharp)<IconProps>`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme, fontSize }) => fontSize ?? theme.fontSizes.ultraLarge};
  margin-right: 8px;
`;

export const Container = styled.div<ContainerProps>`
  width: ${({ width }) => width ?? '400px'};
  margin: ${({ margin }) => margin ?? '15px 0px 0px'};
  height: ${({ heigth }) => heigth ?? 'auto'};
  align-content: ${({ alignContent }) => alignContent ?? 'center'};
`;
