import styled, { css } from 'styled-components';

interface LabelProps {
  disabled: boolean;
  selected: boolean;
  fontSize?: string;
}

export const Container = styled.div`
  display: flex;
  padding: 0 5px 0;
`;

export const Label = styled.div<LabelProps>`
  padding: 2px 20px 2px;
  text-align: center;

  margin: 5px;

  font-style: normal;
  font-size: ${({ theme, fontSize }) => fontSize || theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.medium};

  border-radius: 20px;

  transition-duration: 0.2s;

  ${({ disabled }) => {
    return disabled
      ? css`
          opacity: 0.8;
          cursor: not-allowed;
        `
      : css`
          &:hover {
            cursor: pointer;
            opacity: 0.8;
          }
        `;
  }};
  ${({ selected, theme: { colors } }) => {
    return selected
      ? css`
          color: ${colors.white};
          background: ${colors.secondary};
          border: 1px solid transparent;
        `
      : css`
          color: ${colors.textLight};
          background: transparent;
          border: 1px solid ${colors.textLight};
        `;
  }};
`;
