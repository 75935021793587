import styled from 'styled-components';
import Button from '../Button';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { FaTrashAlt } from 'react-icons/fa';

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  width: inherit;
  outline: 0;
`;

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(23, 25, 28, 0.9);
  z-index: 500;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: 10px 0;
`;

export const Message = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.large};
  padding: 20px 20px 40px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
`;

export const StyledModal = styled.div`
  z-index: 100;
  background: white;
  position: relative;
  margin: auto;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors.mainBackground};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
`;

export const Header = styled.div`
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.mainBackground};
`;

export const HeaderText = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.text};
`;

export const Content = styled.div`
  padding: 10px;
  max-height: 30rem;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
`;

export const Row = styled.div`
  padding: 7px;
  margin-bottom: 7px;
  background-color: ${({ theme }) => theme.colors.cardBackground};
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  justify-content: space-between;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.colors.white};
  max-width: 300px;
  flex-wrap: wrap;
  margin-right: 10px;
`;

export const Date = styled(Description)`
  display: flex;
  align-items: center;
`;

export const CloseButton = styled(Button)`
  background-color: transparent;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  padding: 0;
`;

export const CloseIcon = styled(IoCloseCircleOutline)`
  font-size: ${({ theme }) => theme.fontSizes.ultraLarge};
  margin: 0 10px 0;
  color: ${({ theme }) => theme.colors.textError};
`;

export const ButtonIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 5px;
  height: 30px;
  width: 30px;
  margin-left: 8px;
  min-width: 30px;
`;

export const TrashIcon = styled(FaTrashAlt)`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin: 0 10px 0;
  color: ${({ theme }) => theme.colors.white};
`;
