import { QueryClient, QueryClientProvider } from 'react-query';

import { AppStateProvider } from 'src/hooks/useAppState';
import GlobalSyle from 'src/styles/global';
import React from 'react';
import { SessionProvider } from 'src/hooks/useSession';
import { ThemeProvider } from 'styled-components';
import theme from 'src/styles/themes';

type Props = {
  children: React.ReactNode;
};

const queryClient = new QueryClient();

const AppProvider = ({ children }: Props): JSX.Element => {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <SessionProvider>
            <AppStateProvider>
              <GlobalSyle />
              {children}
            </AppStateProvider>
          </SessionProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
};

export default AppProvider;
