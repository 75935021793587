import { FetchToken, RefreshToken } from 'src/services/authentication';
import { SessionContextType, TSession } from 'src/types';

import LocalStorage from 'src/utils/LocalStorage';
import React from 'react';

const SessionContext = React.createContext<SessionContextType>({} as SessionContextType);

type ProviderProps = {
  children: React.ReactNode;
};

const SessionProvider = ({ children }: ProviderProps): JSX.Element => {
  const [session, setSession] = React.useState({} as TSession);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [isLogged, setLogged] = React.useState<boolean>(false);

  // TODO: integrar com o serviço
  const { refetch: authAsync } = FetchToken();
  const { refetch: refreshAsync } = RefreshToken(session.refreshToken);
  // const { refetch: refetchUser } = FetchUser(session?.token);

  const getStoragedSession = () => LocalStorage.getItem<TSession>('session');

  const updateSession = React.useCallback((newSession: TSession) => {
    LocalStorage.setItem('session', newSession);
    setSession(newSession);
    setLogged(true);
  }, []);

  const clearSession = React.useCallback(() => {
    setSession({} as TSession);
    setLogged(false);
    LocalStorage.clear('session');
  }, []);

  const loadStoragedSession = React.useCallback(() => {
    const storagedSession = getStoragedSession();
    if (storagedSession && Object.keys(storagedSession).length !== 0) {
      updateSession(storagedSession);
    }
  }, [updateSession]);

  const login = React.useCallback(
    async (email: string, password: string) => {
      setLoading(true);
      try {
        const response = await authAsync({ email, password });
        const { token, refreshToken, user } = response.data;
        updateSession({
          token,
          refreshToken,
          user,
        });
        setLoading(false);
      } catch (error) {
        clearSession();
        setLoading(false);
        throw error;
      }
    },
    [authAsync, clearSession, updateSession],
  );

  const refreshSession = React.useCallback(async (): Promise<string | null> => {
    setLoading(true);
    const response = await refreshAsync?.();
    if (!response || !response.data?.data?.token || !response.data?.data?.refreshToken) {
      clearSession();
      setLoading(false);
      return null;
    }
    const { token, refreshToken, user } = response.data.data;
    updateSession({ token, refreshToken, user });
    setLoading(false);
    return session.token;
  }, [refreshAsync, clearSession, session, updateSession]);

  const logout = React.useCallback(() => {
    setLoading(true);
    clearSession();
    setLoading(false);
  }, [clearSession]);

  React.useEffect(() => {
    loadStoragedSession();
  }, [loadStoragedSession]);

  return (
    <SessionContext.Provider
      value={{
        session,
        isLoading,
        isLogged,
        login,
        logout,
        refreshSession,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

const useSession = (): SessionContextType => {
  const context = React.useContext(SessionContext);

  if (!context) {
    throw new Error('useSession must be used within an SessionProvider.');
  }

  return context;
};

export { SessionProvider, useSession };
