import Burger from './Burger';
import { Container } from './styles';
import Menu from './Menu';
import React from 'react';
import { useAppState } from 'src/hooks/useAppState';

const MainMenu: React.FC = () => {
  const { menuState: open, setMenuState: setOpen } = useAppState();
  const node = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!node.current || node.current.contains(event.target as Node)) {
        return;
      }
      setOpen(false);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [node, setOpen]);

  return (
    <Container ref={node}>
      <Burger open={open} setOpen={setOpen} />
      <Menu open={open} />
    </Container>
  );
};

export default MainMenu;
