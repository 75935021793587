import {
  ActionsContainer,
  ButtonIcon,
  CostLabel,
  CostReceipt,
  CustomerIcon,
  DateContainer,
  DeliveryNumber,
  DescriptionContainer,
  EditIcon,
  EmptyMessage,
  EmpytContaienr,
  IsDeletedIcon,
  IsEditedContainer,
  IsEditedIcon,
  Label,
  MotoboyIcon,
  StatusContainer,
  UserContainer,
  ClientContainer,
  DeleteButtonIcon,
  TrashIcon,
} from './styles';

import DataTable, { createTheme } from 'react-data-table-component';
import { IReceipts } from 'src/types';
import Loader from 'src/components/Loader';
import PaymentType from 'src/components/PaymentType';
import React from 'react';
import { dateTimeFormat } from 'src/utils/DateFormatter';
import { useTheme } from 'styled-components';
import { maskToPrice } from 'src/utils/InputMask';
import { HandPay, HistoryIcon } from '../Icon';
import PayDelivery from '../PayDelivery';

interface Props {
  receipts?: IReceipts[];
  totalRows: number | undefined;
  isLoading: boolean;
  onChangePage(page: number): void;
  onShowReceipt(receipt: IReceipts): void;
  onDelete(deliveryId: number): void;
  onShowDetails(receipt: IReceipts): void;
  onPayReceipt(receipt: IReceipts): void;
  filterHeight: number;
}

const DeliveriesTable: React.FC<Props> = ({
  receipts,
  isLoading,
  totalRows,
  onChangePage,
  onShowReceipt,
  onShowDetails,
  onDelete,
  onPayReceipt,
  filterHeight,
}) => {
  const { colors } = useTheme();

  const onOpenReceipt = React.useCallback(
    (e: React.MouseEvent, receipt: IReceipts) => {
      e.preventDefault();
      onShowReceipt(receipt);
    },
    [onShowReceipt],
  );

  createTheme('jucaTheme', {
    text: {
      primary: '#FFFFFF',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(0,0,0,.12)',
    },
    background: {
      default: '#424242',
    },
    context: {
      background: '#E91E63',
      text: '#FFFFFF',
    },
    divider: {
      default: 'transparent',
    },
    button: {
      default: '#FFFFFF',
      focus: 'rgba(255, 255, 255, .54)',
      hover: 'rgba(255, 255, 255, .12)',
      disabled: 'rgba(255, 255, 255, .18)',
    },
    selected: {
      default: 'rgba(0, 0, 0, .7)',
      text: '#FFFFFF',
    },
    highlightOnHover: {
      default: 'rgba(0, 0, 0, .7)',
      text: '#FFFFFF',
    },
    striped: {
      default: 'rgba(0, 0, 0, .87)',
      text: '#FFFFFF',
    },
  });

  const columns = React.useMemo(
    () => [
      {
        name: 'Entrega nº',
        cell: ({ deliveryId, isEdited, isDeleted, motoboy, customer }: IReceipts) => {
          const id = deliveryId.toString().length === 1 ? `0${deliveryId}` : deliveryId;
          const showIcon = isDeleted || isEdited;
          const StatusIcon = showIcon && (
            <StatusContainer color={isEdited ? colors.secondary : colors.textError}>
              {isEdited && !isDeleted && <IsEditedIcon />}
              {isDeleted && <IsDeletedIcon />}
            </StatusContainer>
          );

          return (
            <IsEditedContainer>
              <DeliveryNumber
                color={isEdited || isDeleted ? colors.textLight : colors.primary}
              >
                {id}
                {StatusIcon}
              </DeliveryNumber>
              <ClientContainer>
                <UserContainer>
                  <CustomerIcon
                    color={isEdited || isDeleted ? colors.textLight : colors.primary}
                  />
                  <Label color={isEdited || isDeleted ? colors.textLight : colors.text}>
                    {customer.name}
                  </Label>
                </UserContainer>
                <UserContainer>
                  <MotoboyIcon
                    color={isEdited || isDeleted ? colors.textLight : colors.text}
                  />
                  <Label color={isEdited || isDeleted ? colors.textLight : colors.text}>
                    {motoboy.name}
                  </Label>
                </UserContainer>
              </ClientContainer>
            </IsEditedContainer>
          );
        },
        style: {
          minWidth: '400px',
          maxWidth: '400px',
        },
      },
      {
        name: 'Descrição',
        selector: 'description',
        cell: ({ description, isDeleted, isEdited }: IReceipts) => (
          <DescriptionContainer
            color={isEdited || isDeleted ? colors.textLight : colors.text}
          >
            {description}
          </DescriptionContainer>
        ),
        style: {
          minWidth: '400px',
        },
      },
      {
        name: 'Data',
        selector: 'date',
        cell: ({ originalDate, isDeleted, isEdited }: IReceipts) => (
          <DateContainer color={isEdited || isDeleted ? colors.textLight : colors.text}>
            {dateTimeFormat(new Date(originalDate))}
          </DateContainer>
        ),
        style: {
          maxWidth: '180px',
        },
      },
      {
        name: 'Tipo de pagamento',
        selector: 'paymentType',
        cell: ({ paymentType, isDeleted, isEdited }: IReceipts) => (
          <PaymentType
            color={isEdited || isDeleted ? colors.mainBackground : colors.secondary}
            disabled={isDeleted || isEdited}
            value={paymentType}
            onChange={() => null}
            disabledButton
          />
        ),
        style: {
          maxWidth: '160px',
        },
      },
      {
        name: 'Valor',
        selector: 'cost',
        cell: ({ cost, isDeleted, isEdited }: IReceipts) => (
          <CostReceipt>
            <CostLabel color={isEdited || isDeleted ? colors.textLight : colors.primary}>
              R$ {maskToPrice(cost.toString()).replace('.', ',')}
            </CostLabel>
          </CostReceipt>
        ),
        style: {
          maxWidth: '180px',
        },
      },
      {
        name: 'Pago',
        selector: 'pay',
        cell: ({ pay }: IReceipts) => (
          <CostReceipt>
            <PayDelivery pay={pay} />
          </CostReceipt>
        ),
        style: {
          maxWidth: '180px',
        },
      },
      {
        name: 'Funções',
        cell: (receipt: IReceipts) => (
          <ActionsContainer>
            {receipt.paymentType !== 'billed' && (
              <ButtonIcon
                title={receipt.pay ? 'REMOVER PAGAMENTO' : 'PAGAR'}
                onClick={() => onPayReceipt(receipt)}
              >
                <HandPay color={colors.white} width={20} height={20} />
              </ButtonIcon>
            )}

            {receipt.historic && receipt.historic.length > 0 && (
              <ButtonIcon onClick={() => onShowDetails(receipt)}>
                <HistoryIcon color={colors.white} width={20} height={20} />
              </ButtonIcon>
            )}
            {!receipt.isEdited && !receipt.isDeleted && (
              <>
                <ButtonIcon>
                  <EditIcon onClick={(event) => onOpenReceipt(event, receipt)} />
                </ButtonIcon>
                <DeleteButtonIcon onClick={() => onDelete(receipt.deliveryId)}>
                  <TrashIcon color={colors.white} />
                </DeleteButtonIcon>
              </>
            )}
          </ActionsContainer>
        ),
        ignoreRowClick: true,
        button: true,
      },
    ],
    [colors, onShowDetails, onOpenReceipt, onDelete, onPayReceipt],
  );

  const customStyle = React.useMemo(
    () => ({
      table: {
        style: {
          padding: '0 10px 0',
          backgroundColor: colors.mainBackground,
          color: colors.text,
          overflow: 'auto',
          maxWidth: '100%',
        },
      },
      rows: {
        style: {
          fontSize: '13px',
          color: colors.text,
          backgroundColor: colors.cardBackground,
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.15)',
          minHeight: '60px',
          margin: '8px 0 8px',
          borderRadius: '10px',
        },
      },
      pagination: {
        style: {
          backgroundColor: colors.mainBackground,
          color: colors.white,
        },
      },
      columns: {
        style: {
          minWidth: '160px',
          padding: '10px',
        },
      },
    }),
    [colors],
  );

  return (
    <DataTable
      subHeader={false}
      noHeader
      noTableHead
      responsive
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      onChangePage={(page) => {
        onChangePage(page);
      }}
      style={{
        alignItems: receipts && receipts.length > 0 ? 'flex-start' : 'center',
        justifyContent: 'center',
        height: `calc(100% - ${filterHeight + 60}px)`,
        overflow: 'auto',
      }}
      paginationComponentOptions={{
        rowsPerPageText: 'Quantidade de linhas:',
        rangeSeparatorText: 'Total de Registros:',
        noRowsPerPage: true,
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
      }}
      columns={columns}
      data={receipts == null ? [] : receipts}
      theme="jucaTheme"
      customStyles={customStyle}
      noDataComponent={
        <EmpytContaienr>
          {isLoading ? (
            <Loader color={colors.primary} />
          ) : (
            <EmptyMessage>Lista vazia</EmptyMessage>
          )}
        </EmpytContaienr>
      }
    />
  );
};

export default DeliveriesTable;
