import {
  Motoboy,
  Container,
  FilterContainer,
  StartDateInput,
  EndDateInput,
  Grid,
  Flex,
  Label,
  ExportCard,
  ExportButton,
  ButtonCard,
} from './styles';
import { FaFilePdf } from 'react-icons/fa';
import PaymentType from 'src/components/PaymentType';
import { useTheme } from 'styled-components';

import React from 'react';
import MainContainer from 'src/components/MainContainer';
import MotoboyDeliveriesTable from 'src/components/MotoboyDeliveriesTable';
import MotoboyDeliveryHistoricModal from 'src/components/MotoboyDeliveriesHistoricModal';
import InformationModal, {
  TInformationModalProps,
} from 'src/components/InformationModal';
import {
  IExportResponse,
  IFetchPendencies,
  IGetDeliveries,
  IMotoboyDeliveries,
  TPayment,
} from 'src/types';
import { useSession } from 'src/hooks/useSession';
import { debounce } from 'lodash';
import { FetchDeliverymen, FetchMotoboyDeliveries } from 'src/services/deliverymen';
import { FetchReportStatus } from 'src/services/deliveries';
import LoaderModal from 'src/components/LoadingModal';
import { api_service } from 'src/services/api-service';
import axios, { AxiosError } from 'axios';
import { dateFormatBR } from 'src/utils/DateFormatter';
import PendencyModal from 'src/components/PendencyModal';
import PendencyListModal from 'src/components/PendencyListModal';

interface ShowReceiptModal {
  show: boolean;
  motoboyDeliveries: IMotoboyDeliveries;
}

const MotoboyDeliveries = (): JSX.Element => {
  const { colors } = useTheme();
  const date = new Date();
  const [filters, setFilters] = React.useState<IGetDeliveries>({
    paymentTypes: ['billed', 'money', 'pix'],
    client: undefined,
    motoboy: undefined,
    startdate: new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString(
      'pt-BR',
      {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      },
    ),
    finaldate: date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }),
    limit: 10,
    page: 1,
  });
  const [infoModal, setInfoModal] = React.useState<TInformationModalProps>({
    show: false,
    message: '',
    headerText: '',
  });

  const [loaderModal, setLoaderModal] = React.useState<TInformationModalProps>({
    show: false,
    message: '',
    headerText: '',
  });

  const [showReceiptHistoric, setShowReceiptHistoric] = React.useState<ShowReceiptModal>({
    show: false,
    motoboyDeliveries: {} as IMotoboyDeliveries,
  });

  const [motoboyIdPendency, setMotoboyIdPendency] = React.useState<undefined | number>();

  const [key, setKey] = React.useState('');
  const [pendencies, setPendencies] = React.useState<IFetchPendencies[]>();

  const [filterHeight, setFilterHeight] = React.useState<number>();

  const { session, refreshSession } = useSession();
  const { data, isLoading, refetch } = FetchMotoboyDeliveries(
    session.token,
    refreshSession,
    filters,
  );

  React.useEffect(() => {
    const height = document.getElementById('filters')?.clientHeight;

    setFilterHeight(height);
    refetch?.();
  }, [filters, refetch]);

  const { data: motoboyList } = FetchDeliverymen(session.token, refreshSession);

  const { refetch: fileStatus } = FetchReportStatus(session.token, refreshSession, key);

  const onChangeMotoboy = debounce((value: string) => {
    setFilters({ ...filters, motoboy: value });
  }, 800);

  const onChangeStartDate = (value: string) => {
    const initialDate = dateFormatBR(value);
    setFilters({ ...filters, startdate: initialDate });
  };

  const onChangeFinalDate = (value: string) => {
    const finalDate = dateFormatBR(value);
    setFilters({ ...filters, finaldate: finalDate });
  };

  const onChangePage = (page: number) => {
    setFilters({ ...filters, page });
  };

  const formatDate = (dt: string) => {
    const parts = dt.split('/');

    const dateFormat = parts[2].concat('-').concat(parts[1]).concat('-').concat(parts[0]);

    return dateFormat;
  };

  const onShowReceiptHistoric = React.useCallback(
    (motoboyDeliveries: IMotoboyDeliveries) => {
      setShowReceiptHistoric({ show: true, motoboyDeliveries });
    },
    [],
  );

  const onChangePayment = (value: TPayment) => {
    const index = filters.paymentTypes.findIndex((payment) => payment === value);
    if (index && index < 0) {
      setFilters({ ...filters, paymentTypes: [...filters.paymentTypes, value] });
    } else
      setFilters({
        ...filters,
        paymentTypes: filters.paymentTypes.filter((payment) => payment !== value),
      });
  };

  const onDismissHistoric = () => {
    setShowReceiptHistoric({ show: false, motoboyDeliveries: {} as IMotoboyDeliveries });
  };

  const viewPendencies = (pendenciesData: IFetchPendencies[]) => {
    setPendencies(pendenciesData);
  };

  const showFile = React.useCallback(
    async (url: string, keyValue: string, count: number) => {
      setKey(keyValue);
      if (keyValue.length > 0) {
        const response = await fileStatus?.();
        const file = response?.data?.data?.file;

        if (file && file.status === 'success') {
          setLoaderModal({
            headerText: '',
            message: '',
            show: false,
          });
          setInfoModal({
            headerText: 'Sucesso',
            message: 'O Relatório foi gerado com sucesso',
            show: true,
          });
          window.open(url);
        } else {
          setTimeout(() => {
            showFile(url, keyValue, count - 1);
          }, 3000);
        }
      } else if (count > 0) {
        setTimeout(() => {
          showFile(url, keyValue, count - 1);
        }, 3000);
      } else {
        setInfoModal({
          headerText: 'Erro',
          message: 'Ocorreu um erro ao gerar o relatório.',
          show: true,
        });
      }
    },
    [fileStatus],
  );

  const exportDeliveries = React.useCallback(() => {
    setLoaderModal({
      headerText: 'Atenção',
      message: 'O Relatório está sendo gerado, aguarde.',
      show: true,
    });
    let url = `/generate-report-motoboy/?startdate=${filters.startdate}&finaldate=${filters.finaldate}&paymentTypes=${filters.paymentTypes}`;
    if (filters.client) url = url.concat(`&client=${filters.client}`);
    if (filters.motoboy) url = url.concat(`&motoboy=${filters.motoboy}`);

    api_service
      .get<IExportResponse>(url, {
        headers: { ...axios.defaults.headers, 'x-access-token': session.token },
      })
      .then((response) => {
        if (response.data.url) {
          showFile(response.data.url, response.data.key, 9);
        }
      })
      .catch((err: Error | AxiosError) => {
        let message = '';
        let header = '';
        if (axios.isAxiosError(err)) {
          if (err.response?.status === 400) {
            header = 'Erro';
            message = 'Ocorreu um erro ao processar as informações.';
          } else if (err.response?.status === 404) {
            header = 'Alerta!';
            message = 'Não há dados a serem exportados.';
          } else {
            header = 'Erro';
            message = 'Erro interno no servidor';
          }
        } else {
          header = 'Erro';
          message = 'Erro desconhecido';
        }

        setInfoModal({
          headerText: header,
          message,
          show: true,
        });
      });
  }, [showFile, filters, session.token]);

  const registerPendency = (motoboyId: number) => {
    setMotoboyIdPendency(motoboyId);
  };

  const onSuccessRegisterPendency = () => {
    setMotoboyIdPendency(undefined);
    setInfoModal({
      headerText: 'OK!',
      message: 'Pendência registrado com sucesso',
      show: true,
    });
  };

  const onErrorRegisterPendency = () => {
    setMotoboyIdPendency(undefined);
    setInfoModal({
      headerText: 'Atenção!',
      message: 'Ocorreu um erro ao registrar a pendência',
      show: true,
    });
  };

  return (
    <MainContainer>
      <Container>
        <FilterContainer id="filters">
          <Grid>
            <Label>Filtros</Label>
            <Flex>
              <Motoboy
                label="Buscar motoboy"
                onSelect={(motoboySelect) =>
                  onChangeMotoboy(motoboySelect?.motoboyId?.toString() || '')
                }
                motoboyList={motoboyList?.data?.data || []}
                showAll
              />
            </Flex>
          </Grid>
          <Flex>
            <Grid>
              <Label>Data inicial</Label>
              <StartDateInput
                text={formatDate(filters.startdate)}
                onChangeValue={onChangeStartDate}
              />
            </Grid>
            <Grid>
              <Label>Data final</Label>
              <EndDateInput
                text={formatDate(filters.finaldate)}
                onChangeValue={onChangeFinalDate}
              />
            </Grid>
          </Flex>
          <Grid>
            <Label>Tipo de pagamento</Label>
            <Flex>
              <ButtonCard>
                <PaymentType
                  value="billed"
                  onChange={onChangePayment}
                  disabled={!filters.paymentTypes.includes('billed')}
                />
              </ButtonCard>
              <ButtonCard>
                <PaymentType
                  value="pix"
                  onChange={onChangePayment}
                  disabled={!filters.paymentTypes.includes('pix')}
                />
              </ButtonCard>
              <ButtonCard>
                <PaymentType
                  value="money"
                  onChange={onChangePayment}
                  disabled={!filters.paymentTypes.includes('money')}
                />
              </ButtonCard>
            </Flex>
          </Grid>
          <Grid>
            <Label>Exportar</Label>
            <ExportCard>
              <ExportButton onClick={exportDeliveries}>
                <FaFilePdf color={colors.text} />
                <Label>PDF</Label>
              </ExportButton>
            </ExportCard>
          </Grid>
        </FilterContainer>
        <MotoboyDeliveriesTable
          filtersHeight={filterHeight ?? 0}
          motoboys={data?.data?.motoboys}
          totalRows={data?.data?.total}
          isLoading={isLoading}
          onShowDetails={onShowReceiptHistoric}
          onChangePage={onChangePage}
          registerPendency={registerPendency}
          viewPendencies={viewPendencies}
        />
      </Container>
      <MotoboyDeliveryHistoricModal
        onDismiss={onDismissHistoric}
        motoboyDeliveries={showReceiptHistoric.motoboyDeliveries}
        show={showReceiptHistoric.show}
      />
      <InformationModal
        headerText={infoModal.headerText}
        show={infoModal.show}
        onDismiss={() => setInfoModal({ headerText: '', message: '', show: false })}
        message={infoModal.message}
      />
      <LoaderModal
        headerText={loaderModal.headerText}
        message={loaderModal.message}
        show={loaderModal.show}
      />
      <PendencyModal
        motoboyId={motoboyIdPendency ?? -1}
        show={motoboyIdPendency !== undefined}
        onSuccess={onSuccessRegisterPendency}
        onError={onErrorRegisterPendency}
        onDismiss={() => setMotoboyIdPendency(undefined)}
      />
      <PendencyListModal
        show={pendencies !== undefined}
        onDismiss={() => {
          setPendencies(undefined);
          setMotoboyIdPendency(undefined);
        }}
        pendencies={pendencies ?? []}
      />
    </MainContainer>
  );
};

export default MotoboyDeliveries;
