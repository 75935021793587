export default (text: string): string => {
  const numbersDigits = text.replace(/\D/g, '');

  if (numbersDigits.length > 2) {
    return numbersDigits.replace(/^0+(\d)$/gm, '$1$2').replace(/(\d)(\d{2})$/, '$1,$2');
  }

  if (numbersDigits.length === 2) {
    return numbersDigits.replace(/(\d{2})$/, '0,$1');
  }

  if (numbersDigits.length === 1) {
    return numbersDigits.replace(/(\d)$/, '0,0$1');
  }

  return '';
};
