import styled from 'styled-components';
import { FaMotorcycle } from 'react-icons/fa';
import { IoBriefcaseSharp } from 'react-icons/io5';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MotoboyIcon = styled(FaMotorcycle)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.primary};
`;
export const MotoboyName = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.large};
  padding-left: 6px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

export const DeliveriesIcon = styled(IoBriefcaseSharp)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.primary};
`;

export const CashIcon = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.primary};
`;

export const TotalDeliveries = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding-left: 6px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  min-width: 115px;
`;

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.mainBackground};
  width: 100%;
  height: 100%;
`;

export const EmptyMessage = styled.div`
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.text};
`;

export const ButtonIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 5px;
  height: 30px;
  width: 30px;
  margin-right: 8px;
  min-width: 30px;
`;
